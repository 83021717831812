<template>
  <v-form ref="form" v-model="valid" @input="$emit('validity-changed', $event)">
    <div class="d-flex align-end gap-md mb-1">
      <AreaProfileMenu
        v-if="isIndividual && buildingProfiles.length"
        :profiles="buildingProfiles"
        @input="handleProfileSelect"
      />
    </div>

    <v-row dense>
      <v-col cols="8">
        <label
          for="land_area"
          class="caption"
          :class="{ 'required': isLandAreaRequired }"
        >
          Land Area
          <span v-if="isLandAreaRequired">*</span>
        </label>
        <CurrencyText
          id="land_area"
          v-model="local.land_area"
          hide-details
          :rules="isLandAreaRequired ? [$rules.required] : []"
          :units="dropdowns.areaUnits"
        />
      </v-col>
    </v-row>
    <v-row dense class="mb-2">
      <v-col cols="6">
        <v-checkbox
          v-model="local.is_easement"
          label="This property has an easement"
          hide-details
          class="ml-n1"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="6">
        <label for="hardstand_area" class="caption">Hardstand Area (Optional)</label>
        <CurrencyText
          id="hardstand_area"
          v-model="local.hardstand_area"
          hide-details
          :units="dropdowns.areaUnits"
        />
      </v-col>
      <v-col cols="6">
        <label for="developable_area" class="caption">Developable Area (Optional)</label>
        <CurrencyText
          id="developable_area"
          v-model="local.developable_area"
          hide-details
          :units="dropdowns.areaUnits"
        />
      </v-col>
    </v-row>
    
    <ExpandPanel label="Land shape, Street frontages" class="mt-6">
      <v-row dense>
        <v-col cols="6">
          <label for="land_shape_id" class="caption">Land Shape</label>
          <BtnSelection
            v-model="local.land_shape_id"
            ky="landShapes"
            grow
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <label class="caption" for="number_of_street_frontages">Number of Street Frontages</label>
          <DropdownSelect
            id="number_of_street_frontages"
            v-model="local.number_of_street_frontages"
            hide-details
            ky="nStreetFrontages"
          />
        </v-col>
      </v-row>
      <div v-if="local.number_of_street_frontages" class="mt-2">
        <div v-for="i in local.number_of_street_frontages" :key="i" class="d-flex align-end gap-sm">
          <v-row dense>
            <v-col cols="6">
              <label :for="`street_${i}_length`" class="caption">
                Street Frontage {{ i }}
              </label>
              <CurrencyText
                :id="`street_${i}_length`"
                v-model="local[`street_${i}_length`]"
                :has-unit="false"
                suffix="meter(s)"
                hide-details
              />
            </v-col>
            <v-col cols="6">
              <label :for="`street_${i}_name`" class="caption">
                Street Name
              </label>
              <v-text-field
                :id="`street_${i}_name`"
                v-model="local[`street_${i}_name`]"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
          <v-autocomplete
            v-model="local[`street_${i}_name_type_id`]"
            :items="dropdowns.streetTypes"
            item-value="id"
            item-text="name"
            outlined
            mandatory
            hide-details
            :clearable="false"
            style="max-width: 180px;"
          />
        </div>
      </div>
    </ExpandPanel>

    <div v-if="showPermit" class="mt-5">
      <p class="font-weight-bold primary--text mb-0">Development Permit Info</p>
      <v-divider></v-divider>

      <div>
        <BtnSelection
          v-model="local.permit_approved_status_id"
          ky="permitApprovedStatuses"
          class="mt-3"
        />
      </div>
      
      <ToggleInput
        v-model="local.add_permit"
        :items="[
          { value: false, text: 'No Permit Info' },
          { value: true, text: 'Add Permit Info' }
        ]"
        class="mt-2 mb-2"
        @input="handleAddPermitChange"
      />
      <v-row dense v-if="local.add_permit">
        <v-col cols="6">
          <label for="number_of_approved_childcare_places" class="caption">Number of Approved Childcare Places (Optional)</label>
          <CurrencyText
            id="number_of_approved_childcare_places"
            v-model="local.number_of_approved_childcare_places"
            :has-unit="false"
            hide-details
            :with-precision="false"
          />
        </v-col>
        <v-col cols="6">
          <label for="number_of_approved_rooms" class="caption">Number of Approved Rooms/Keys (Optional)</label>
          <CurrencyText
            id="number_of_approved_rooms"
            v-model="local.number_of_approved_rooms"
            :has-unit="false"
            hide-details
            :with-precision="false"
          />
        </v-col>
        <v-col cols="6">
          <label for="total_sellable_area" class="caption">Total Sellable Area / Lettable Area (optional)</label>
          <CurrencyText
            id="total_sellable_area"
            v-model="local.total_sellable_area"
            hide-details
            :units="dropdowns.areaUnits"
          />
        </v-col>
        <v-col cols="6">
          <label for="permit_application_number" class="caption">Permit Application Number (optional)</label>
          <v-text-field
            v-model="local.permit_application_number"
            id="permit_application_number"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label for="gross_floor_area" class="caption">Gross Floor Area (optional)</label>
          <CurrencyText
            id="gross_floor_area"
            v-model="local.gross_floor_area"
            hide-details
            :units="dropdowns.areaUnits"
          />
        </v-col>
        <v-col cols="6">
          <label for="building_height" class="caption">Building Height (optional)</label>
          <CurrencyText
            id="building_height"
            v-model="local.building_height"
            hide-details
            :units="['Meters', 'Meters(RL)', 'Storeys']"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <label for="type_ids" class="caption">Existing Use (optional) (Referring to previous use)</label>
          <PropertyTypeMultiSelect
            id="type_ids"
            v-model="local.existing_use_type_ids"
            dense
            outlined
            hide-details
          />
        </v-col>
      </v-row>

      <ExpandPanel label="Development Dwelling Breakdown" class="mt-5">
        <div v-for="(dwelling, i) in local.dwellings" :key="i">
          <div>Product {{ i + 1 }}</div>

          <v-row dense class="ml-3 mb-3">
            <v-col cols="7">
              <label :for="`product_type_id_${i + 1}`" class="caption">Type {{ i + 1 }}</label>
              <DropdownSelect
                :id="`product_type_id_${i + 1}`"
                v-model="dwelling.product_type_id"
                ky="developmentSiteTypes"
                hide-details
              />
            </v-col>
            <v-col cols="5">
              <label :for="`yield_count_${i + 1}`" class="caption">Yield/Count</label>
              <v-text-field
                :id="`yield_count_${i + 1}`"
                v-model="dwelling.yield_count"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <label :for="`min_area_${i + 1}`" class="caption">Min Area</label>
              <CurrencyText
                :id="`min_area_${i + 1}`"
                v-model="dwelling.min_area"
                hide-details
                :units="dropdowns.areaUnits"
              />
            </v-col>
            <v-col cols="4">
              <label :for="`max_area_${i + 1}`" class="caption">Max Area</label>
              <CurrencyText
                :id="`max_area_${i + 1}`"
                v-model="dwelling.max_area"
                hide-details
                :units="dropdowns.areaUnits"
              />
            </v-col>
            <v-col cols="4">
              <label :for="`average_area_${i + 1}`" class="caption">Average Area</label>
              <CurrencyText
                :id="`average_area_${i + 1}`"
                v-model="dwelling.average_area"
                hide-details
                :units="dropdowns.areaUnits"
              />
            </v-col>
            <v-col cols="4">
              <label :for="`config_bed_${i + 1}`" class="caption">Config Bed</label>
              <CurrencyText
                :id="`config_bed_${i + 1}`"
                v-model="dwelling.config_bed"
                hide-details
                :has-unit="false"
                :with-precision="false"
              />
            </v-col>
            <v-col cols="4">
              <label :for="`config_bath_${i + 1}`" class="caption">Config Bath</label>
              <CurrencyText
                :id="`config_bath_${i + 1}`"
                v-model="dwelling.config_bath"
                hide-details
                :has-unit="false"
                :with-precision="false"
              />
            </v-col>
            <v-col cols="4">
              <label :for="`config_car_${i + 1}`" class="caption">Config Car</label>
              <CurrencyText
                :id="`config_car_${i + 1}`"
                v-model="dwelling.config_car"
                hide-details
                :has-unit="false"
                :with-precision="false"
              />
            </v-col>
          </v-row>
        </div>

        <v-row dense class="mt-3">
          <v-col cols="6">
            <label for="number_of_floors" class="caption">Number of Floors (Optional)</label>
            <v-text-field
              v-model="local.number_of_floors"
              id="number_of_floors"
              type="number"
              min="0"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <label for="number_of_basement_floors" class="caption">Number of Basement Floors (Optional)</label>
            <v-text-field
              v-model="local.number_of_basement_floors"
              id="number_of_basement_floors"
              type="number"
              min="0"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="7">
            <label for="parking_type_ids" class="caption">Parking Types (Optional)</label>
            <DropdownSelect
              id="parking_type_ids"
              v-model="local.parking_type_ids"
              ky="parkingTypes"
              :multiple="true"
              hide-details
            />
          </v-col>
          <v-col cols="5">
            <label for="number_of_car_spaces" class="caption">Number of Car Spaces (Optional)</label>
            <v-text-field
              v-model="local.number_of_car_spaces"
              id="number_of_car_spaces"
              outlined
              type="number"
              min="0"
              suffix="space(s)"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </ExpandPanel>
    </div>
  </v-form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { includesAny } from '@/helper/array'

import PropertyTypeMultiSelect from '@/components/PropertyTypeMultiSelect.vue'
import AreaProfileMenu from '@/components/AreaProfileMenu'

export default {
  props: [
    'value',
    'typeIds',
    'isIndividual',
    'address_detail_pid'
  ],
  
  components: {
    PropertyTypeMultiSelect,
    AreaProfileMenu
  },

  data() {
    return {
      includesAny,

      valid: true,
      buildingProfiles: []
    }
  },

  watch: {
    address_detail_pid: {
      immediate: true, // run watcher on mounted()
      handler(v) {
        if (v && this.isIndividual) {
          this.getBuildingProfiles()
        }
      }
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapGetters('dropdown', ['isDevelopmentSiteSelected']),
    local() {
      return this.value
    },
    showPermit() {
      return this.isDevelopmentSiteSelected(this.typeIds)
    },
    isLandAreaRequired() {
      if (this.salesType === 'salesComp') {
        return this.local.existing_rights_of_transfer_id !== 2
      }

      return false
    }
  },

  methods: {
    ...mapActions('property', [
      'getCompsByAddressDetailPid',
      'getCompTransactions'
    ]),
    handleAddPermitChange() {
      // 
    },
    // isLeaseProfileFilled(address_detail_pid) {
    //   if (!this.leaseProfiles) {
    //     return false
    //   }

    //   return !!(this.leaseProfiles[address_detail_pid].commencing_rent && this.leaseProfiles[address_detail_pid].lease_dt)
    // },
    getBuildingProfiles() {
      this.getCompTransactions({
        address_detail_pids: [this.address_detail_pid],
        with_building_details: true
      }).then((res) => {
        const { salesItems, leaseItems } = res.data

        this.buildingProfiles = [...salesItems, ...leaseItems].sort((a, b) => {
          const dtA = a.sale_date ? a.sale_date : (a.lease_date ? a.lease_date : 0)
          const dtB = b.sale_date ? b.sale_date : (b.lease_date ? b.lease_date : 0)

          return dtA > dtB ? -1 : (dtA < dtB ? 1 : 0)
        })
      })
    },
    handleProfileSelect(item) {
      const building = item.building

      this.$emit('input', {
        ...this.local,

        land_area: {
          value: building.land_area,
          unit: building.land_area_unit
        },
        hardstand_area: {
          value: building.hardstand_area,
          unit: building.hardstand_area_unit
        },
        developable_area: {
          value: building.developable_area,
          unit: building.developable_area_unit
        },
        land_shape_id: building.land_shape_id,
        number_of_street_frontages: building.number_of_street_frontages,
        street_1_length: building.street_1_length,
        street_1_name: building.street_1_name,
        street_1_name_type_id: building.street_1_name_type_id,
        street_2_length: building.street_2_length,
        street_2_name: building.street_2_name,
        street_2_name_type_id: building.street_2_name_type_id,
        street_3_length: building.street_3_length,
        street_3_name: building.street_3_name,
        street_3_name_type_id: building.street_3_name_type_id,
        street_4_length: building.street_4_length,
        street_4_name: building.street_4_name,
        street_4_name_type_id: building.street_4_name_type_id,

        permit_approved_status_id: building.permit_approved_status_id,
        add_permit: building.add_permit,
        number_of_approved_childcare_places: building.number_of_approved_childcare_places,
        number_of_approved_rooms: building.number_of_approved_rooms,
        total_sellable_area: {
          value: building.total_sellable_area,
          unit: building.total_sellable_area_unit
        },
        permit_application_number: building.permit_application_number,
        gross_floor_area: {
          value: building.gross_floor_area,
          unit: building.gross_floor_area_unit
        },
        building_height: {
          value: building.building_height,
          unit: building.building_height_unit
        },
        existing_use_type_ids: building.existing_use_type_ids,
        dwellings: building.dwellings
      })
    }
  }
}
</script>