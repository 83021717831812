import Vue from 'vue'
import api from '@/api'
import router from '../../router'
import companyNames from '@/@propedia/constants/company_names'

export default {
  namespaced: true,

  state: {
    token: null,
    user: {
      username: null,
      email: null,
      role: null,
      profile: null,
      company: null
    },
    profile: null
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_AUTH_DATA(state, user) {
      if (user) {
        state.user = user
      }
    }
  },
  actions: {
    async signIn({ commit }, payload) {
      const response = await api.post('auth/login', payload)

      commit('SET_TOKEN', response.data.token)
      commit('SET_AUTH_DATA', response.data.user)
      // commit('SET_PROFILE', response.data.user.profile)

      Vue.$cookies.set('token', response.data.token)

      return response
    },
    async signUp({ commit }, payload) {
      const response = await api.post('auth/register', payload)

      commit('SET_TOKEN', response.data.token)
      commit('SET_AUTH_DATA', response.data.user)
      // commit('SET_PROFILE', response.data.user.profile)

      Vue.$cookies.set('token', response.data.token)
    },
    async logout({ commit }) {
      await api.get('/auth/logout')

      commit('SET_TOKEN', null)
      commit('SET_AUTH_DATA', null)

      Vue.$cookies.remove('token')
      router.push('/auth/signin')
    },
    async getMe({ commit }) {
      const res = await api.get('/auth/me')
      
      commit('SET_AUTH_DATA', res.data.user)
    },
    async changePassword(_, payload) {
      return api.post('/auth/change-password', payload)
    },
    async sendPasswordResetLink(_, payload) {
      return api.post('/auth/password-reset', payload)
    },
    async resetPassword(_, payload) {
      return api.post('/auth/reset-password', payload)
    },
    async savePersonalInfo(_, payload) {
      return api.put('/auth/personal_info', payload)
    }
  },
  getters: {
    isCKC: (store) => {
      return store.user.company?.name === companyNames.CKC
    },
    fullName: (store) => {
      return `${store.user.first_name} ${store.user.last_name}`
    },
    isAuth: (store) => {
      return store.token
    },
    isAdmin: (store) => {
      return store.user.roles[0].name === 'ADMIN'
    },
    isCompany: (store) => {
      return store.user.roles[0].name === 'COMPANY'
    },
    userRole: (store) => {
      return store.user.roles[0].name
    }
  }
}