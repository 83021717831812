import Vue from 'vue'
import Router from 'vue-router'
import authRoutes from './auth.routes'
// import adminRoutes from './admin.routes'
import profileRoutes from './profile.routes'
import myCompsRoutes from './me.comps.routes'
import devRoutes from './dev.routes'
import dashboardRoutes from './dashboard.routes'

import store from '@/store'
import bootstrap from './bootstrap'
import api from '@/api'

import { setLoading, logout } from '@/helper/app'

import companyNames from '@/@propedia/constants/company_names'

Vue.use(Router)

export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/HomePage.vue'),
    meta: {
      layout: 'static'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/pages/PrivacyPage.vue'),
    meta: {
      layout: 'static'
    }
  },
  {
    path: '/propedia-explorer',
    name: 'propedia-explorer',
    component: () => import('@/pages/ExplorerPage.vue'),
    meta: {
      layout: 'static'
    }
  },
  {
    path: '/fullstack-development-service',
    name: 'fullstack-development-service',
    component: () => import('@/pages/FullstackServicePage.vue'),
    meta: {
      layout: 'static'
    }
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import('@/pages/TeamsPage.vue'),
    meta: {
      layout: 'static'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/pages/TermsPage.vue'),
    meta: {
      layout: 'static'
    }
  }, {
    path: '/contact',
    name: 'contact',
    component: () => import('@/pages/ContactPage.vue'),
    meta: {
      layout: 'static'
    }
  }, {
    path: '/browse/list',
    name: 'browse.list',
    component: () => import('@/pages/ListView.vue'),
    meta: {
      layout: 'default',
      salesTypeRequired: true,
      navigation: 'list',
      requiresAuth: true
    }
  },
  {
    path: '/browse/map',
    name: 'browse.map',
    component: () => import('@/pages/MapView.vue'),
    meta: {
      layout: 'default',
      salesTypeRequired: true,
      navigation: 'map',
      requiresAuth: true
    }
  },
  {
    path: '/comps/create',
    name: 'comps.create',
    component: () => import('@/pages/CompCreate.vue'),
    meta: {
      layout: 'default',
      salesTypeRequired: true,
      requiresAuth: true
    }
  },
  {
    path: '/comps/create-bulk',
    name: 'comps.create-bulk',
    component: () => import('@/pages/CompCreateBulk.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/comps/:comp_id',
    name: 'comps.detail',
    component: () => import('@/pages/CompDetailPage.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/addresses/create',
    name: 'address.create',
    component: () => import('@/pages/AddressCreate.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  ...authRoutes,
  // ...adminRoutes,
  ...profileRoutes,
  ...myCompsRoutes,
  ...devRoutes,
  ...dashboardRoutes,
  {
    path: '/reporting/create',
    name: 'reporting.create',
    component: () => import('@/pages/reporting/CreatePage.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  }, {
    path: '/maintenance',
    name: 'MaintenancePage',
    component: () => import('@/pages/error/MaintenancePage.vue'),
    meta: {
      requiresMaintenance: true
    }
  }, {
    path: '*',
    name: 'error',
    component: () => import('@/pages/error/NotFoundPage.vue')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    if (to.hash) {
      return { selector: to.hash }
    }

    return { x: 0, y: 0 }
  },
  routes
})

let firstRoute = true

/**
 * Before each route update
 */
router.beforeEach(async (to, from, next) => {
  store.commit('SET_ROUTE', to)

  // from.name !== to.name && setLoading(true)
  setLoading(true)

  if (firstRoute) {
    firstRoute = false
    
    await bootstrap()
  }

  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin)
  const requiresCompanyAdmin = to.matched.some((record) => record.meta.requiresCompanyAdmin)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest)
  const requiresDev = to.matched.some((record) => record.meta.requiresDev)
  const requiresMaintenance = to.matched.some((record) => record.meta.requiresMaintenance)

  if (requiresMaintenance) {
    const res = await api.get('/settings/under_maintenance')

    if (res.data.value !== 'true') {
      window.location = '/browse/map?sales_type=salesComp'
    }
  }

  const authenticated = !!store.state.auth.token

  if (
    to.matched.some((record) => record.meta.salesTypeRequired) &&
    !['salesComp', 'leaseComp'].includes(to.query.sales_type)
  ) {
    return next({ name: to.name, query: { ...to.query, sales_type: 'salesComp' } })
  }

  if (to.name === 'comp_reviews') {
    if (store.state.auth.user.company.name === companyNames.CKC) {
      window.location = '/dashboard'
    }
  }

  if (requiresDev && (!store.state.auth.user.roles || store.state.auth.user.roles[0].name !== 'DEV') ||
    requiresAdmin && (!store.state.auth.user.roles || store.state.auth.user.roles[0].name !== 'ADMIN') ||
    requiresCompanyAdmin && (!store.state.auth.user.roles || store.state.auth.user.roles[0].name !== 'COMPANY') ||
    requiresAuth && !authenticated
  ) {
    logout(Vue)
  } else if (requiresGuest && authenticated) {
    return next({ name: 'browse.list' })
  }

  return next()
})

/**
 * After each route update
 */
router.afterEach(() => {
  setLoading(false)
})

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
