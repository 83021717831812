const {
  APPROVED,
  PENDING,
  REJECTED
} = require('@/@propedia/constants/statuses')

module.exports = {
  setLoading: (loading) => {
    const dom = document.getElementById('loading')

    if (loading) {
      dom.style.display = 'flex'
      dom.style.opacity = 1
    } else {
      dom.style.opacity = 0
      setTimeout(() => {
        dom.style.display = 'none'
      }, 300)
    }
  },

  statusColor: (status) => {
    switch (status) {
      case APPROVED:
        return 'success'

      case PENDING:
        return 'yellow darken-3'

      case REJECTED:
        return 'error'

      default:
        return '';
    }
  },

  statusName: (status) => {
    switch (status) {
      case APPROVED:
        return 'Verified'

      case PENDING:
        return 'Unverified'

      case REJECTED:
        return 'Rejected'

      case 3:
        return 'Removed'

      default:
        return '-';
    }
  },

  logout: ($vm) => {
    $vm.$cookies.remove('token')
    window.location = '/auth/signin'
  }
}