<template>
  <div>
    <v-navigation-drawer
      :value="true"
      app
      clipped
      stateless
      floating
      class="elevation-0"
    >
      <v-list subheader dense nav>
        <template
          v-for="(item, i) in navigationItems"
        >
          <v-divider v-if="item.divider" :key="`divider-${i}`" class="my-2" />
          <v-subheader v-else-if="item.subheader" :key="`subheader-${i}`">{{ item.subheader }}</v-subheader>
          <v-list-item
            v-else-if="item.for.includes(userRole)"
            :key="`item-${i}`"
            :to="item.to"
            link
            active-class="primary--text"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      elevation="1"
      color="white"
      clipped-left
      clipped-right
      style="z-index: 10;"
    >
      <div
        class="d-flex flex-grow-1 justify-space-between align-center mx-auto"
      >
        <div cols="4" class="h-100 py-0 pl-0 d-flex align-center">
          <logo-component />
        </div>

        <div cols="6" class="d-flex align-center justify-end py-0 pr-0 gap-sm">
          <v-btn
            color="primary"
            text
            :href="`/browse/map?sales_type=${salesType}`"
          >
            <v-icon left class="">$mdi-compass-outline</v-icon>
            Browse comps
          </v-btn>
          <user-menu v-if="isAuth" />
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'

import LogoComponent from '@/components/LogoComponent.vue'
import UserMenu from '@/components/UserMenu.vue'

export default {
  components: {
    LogoComponent,
    UserMenu
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', [
      'isAuth',
      'isAdmin',
      'userRole'
    ]),
    ...mapGetters(['salesType']),
    ...mapGetters('auth', ['isCKC', 'isCompany']),
    navigationItems() {
      if (this.isAdmin) {
        return [
          {
            to: '/dashboard',
            icon: '$mdi-view-dashboard',
            title: 'Dashboard',
            for: ['ADMIN']
          },
          {
            subheader: 'Management'
          }, {
            to: '/companies',
            icon: '$mdi-domain',
            title: 'Companies',
            for: ['ADMIN']
          }, {
            to: '/reporting_templates',
            icon: '$mdi-file-word',
            title: 'Reporting Templates',
            for: ['ADMIN']
          }
        ]
      } else {
        return [
          {
            to: '/dashboard',
            icon: '$mdi-view-dashboard',
            title: 'Dashboard',
            for: ['USER', 'COMPANY']
          }, {
            subheader: 'Valuation'
          }, {
            to: '/jobs',
            icon: '$mdi-laptop',
            title: 'Jobs',
            for: ['USER', 'COMPANY']
          }, {
            to: '/reports',
            icon: '$mdi-chart-bar',
            title: 'Reports',
            for: ['USER', 'COMPANY']
          },
          ...(this.isCKC ? [] : [{
              to: '/comp_reviews',
              icon: '$mdi-domain',
              title: 'Reviews',
              for: ['USER', 'COMPANY']
            }]),
          ...(this.isCompany ? [
            {
              subheader: 'Management'
            }, {
              to: '/users',
              icon: '$mdi-account-multiple',
              title: 'Users',
              for: ['COMPANY']
            }, {
              to: '/addresses',
              icon: '$mdi-map-marker',
              title: 'Manual Addresses',
              for: ['COMPANY']
            }, {
              to: '/sales',
              icon: '$mdi-sale',
              title: 'Comps',
              for: ['COMPANY']
            }
          ] : []), {
            subheader: 'Account'
          }, {
            to: '/account',
            icon: '$mdi-card-account-mail',
            title: 'Personal Info',
            for: ['USER', 'COMPANY']
          }, {
            to: '/account_settings',
            icon: '$mdi-lock',
            title: 'Change Password',
            for: ['USER', 'COMPANY']
          }, {
            subheader: 'System Monitor'
          }, {
            to: '/activities_logs',
            icon: '$mdi-history',
            title: 'Activities & Logs',
            for: ['COMPANY']
          }, {
            to: '/files_backups',
            icon: '$mdi-file-table',
            title: 'Files & Backup',
            for: ['COMPANY']
          }, {
            divider: true
          }, {
            to: '/settings',
            icon: '$mdi-cog',
            title: 'Settings',
            for: ['USER', 'COMPANY']
          }, {
            to: '/help',
            icon: '$mdi-help-circle-outline',
            title: 'Help',
            for: ['USER', 'COMPANY']
          }, {
            to: '/send_feedback',
            icon: '$mdi-comment-quote',
            title: 'Send Feedback',
            for: ['USER', 'COMPANY']
          }
        ]
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    // ...mapActions('dropdown', ['getDropdowns']),
    // ...mapActions('setting', ['getColumns', 'getUserColumns']),
    // ...mapActions('zoning_and_overlay', ['getZoneCodes', 'getOverlayCodes']),
    init() {
      // this.getDropdowns()
      // this.getColumns().then(() => {
      //   this.getUserColumns()
      // })

      // this.getZoneCodes()
      // this.getOverlayCodes()
    },
  }
}
</script>