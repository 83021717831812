<template>
  <div>
    <div v-if="label" class="caption">{{ label }}</div>
    <div
      v-if="typeIds"
    >
      <div v-if="eachLine" class="d-flex flex-wrap flex-column">
        <div v-for="(item) in typesFromIds(typeIds)" :key="item.id">
          <v-chip
            v-if="
              item.parent_id ||
              (item.parent_id === null && arrayUtil.howManyInArray(typeIds, childrenIds(item)) === 0)
            "
            small
            label
            :color="color"
          >
            <template v-if="item.parent_id">
              <span>{{ parentTypeName(item.id) }} > {{ item.name }}</span>
            </template>
            <span v-else>{{ item.name }}</span>
          </v-chip>
        </div>
      </div>
      <div v-else class="d-flex flex-wrap gap-xs">
        <template v-for="(item) in typesFromIds(typeIds)">
          <div v-if="
              item.parent_id ||
              (item.parent_id === null && arrayUtil.howManyInArray(typeIds, childrenIds(item)) === 0)
            "
            :key="item.id"
          >
            <v-chip
              small
              label
              :color="color"
            >
              <template v-if="item.parent_id">
                <span>{{ parentTypeName(item.id) }} > {{ item.name }}</span>
              </template>
              <span v-else>{{ item.name }}</span>
            </v-chip>
          </div>
        </template>
      </div>
    </div>
    <v-icon v-else small>$mdi-lock</v-icon>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import arrayUtil from '@/helper/array'

export default {
  props: {
    unlocked: {
      type: Boolean,
      default: true
    },
    typeIds: {
      type: Array,
      default: () => []
    },
    label: {
      required: false
    },
    eachLine: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    }
  },

  data() {
    return {
      arrayUtil
    }
  },

  computed: {
    ...mapGetters('dropdown', [
      'parentTypeName',
      'childrenIds',
      'typesFromIds'
    ])
  }
}
</script>