<template>
  <v-dialog
    :value="value"
    persistent
    no-click-animation
    scrollable
    max-width="1400"
  >
    <v-card>
      <v-card-title class="py-1">
        <slot name="title">
          {{ salesType === 'salesComp' ? 'Edit sales comp details below' : 'Edit lease comp details below' }}
        </slot>
        <v-spacer />
        <v-icon @click="closeEdit">$mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-0 pb-2">
        <div class="d-flex align-start justify-center gap-md mt-2">
          <div>
            <div class="mb-3">
              <div class="d-flex align-center">
                <v-text-field
                  v-model="salesForm.address_label"
                  filled
                  rounded
                  placeholder="Address Label"
                  hide-details
                />
                <v-btn text color="primary" class="ml-2">Save a Draft</v-btn>
              </div>
            </div>

            <div class="d-flex align-start gap-md">
              <v-card>
                <v-list
                  nav
                  :ripple="false"
                  width="280"
                  min-width="280"
                  class="stepper-nav"
                >
                  <v-list-item-group
                    v-model="stepper"
                    color="primary"
                    mandatory
                  >
                    <template
                      v-for="(item, i) in steps"
                    >
                      <v-list-item
                        v-if="salesForm.tenure_type_id === 2 ? true : !item.parent_id"
                        :key="item.id"
                        :value="item.id"
                        :class="{'pl-4': item.parent_id}"
                      >
                        <v-list-item-icon>
                          <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            <div v-if="i === 3">{{  isSalesComp ? 'Sales Analysis' : 'Lease Analysis' }}</div>
                            <div v-else>{{ item.text }}</div>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card>
    
              <div
                style="
                  min-width: 680px;
                  max-width: 780px;
                  width: 0px;
                  flex-grow: 1;
                "
              >
                <v-card
                  class="flex-grow-1"
                  style="
                    width: 100%;
                    overflow: hidden;
                  "
                >
                  <div style="
                    max-height: calc(100vh - 240px);
                    min-height: 500px;
                    overflow-y: scroll;
                  ">
                    <v-card-text>
                      <v-skeleton-loader
                        v-if="loading"
                        type="article, list-item-three-line"
                      ></v-skeleton-loader>
                      <v-window v-else v-model="stepper">
                        <v-window-item :value="1" eager>
                          <v-form ref="form1">
                            <v-row dense>
                              <v-col cols="12" style="position: relative;">
                                <div v-if="salesForm.is_multi_sale">
                                  <div
                                    v-for="(add, i) in addresses"
                                    :key="i"
                                  >
                                    <v-chip
                                      small
                                      label
                                      class="mr-1 mb-1"
                                    >{{ alphabeticalIndex(i, true) }}) {{ add.full_address }}</v-chip>
                                  </div>
                                </div>
                                <div v-else>
                                  {{ address?.full_address }}
                                </div>
                              </v-col>
                              <v-col cols="12">
                                <div class="d-flex align-center ml-n1">
                                  <v-checkbox
                                    v-model="salesForm.is_multi_sale"
                                    label="This is a Multiple Properties in a Single Sale"
                                    hide-details
                                    class="mt-0 pt-0"
                                    disabled
                                  />
                                  <v-tooltip right max-width="300">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
                                    </template>
                                    <span>A <b>multi-property sale</b> occurs when a buyer purchases more than one <b>property</b> at once. Please select this option if a sale is multi-property sale.</span>
                                  </v-tooltip>
                                </div>
                              </v-col>
          
                              <v-col cols="12">
                                <label for="type_ids" class="caption required">Property Type <span>*</span></label>
                                <PropertyTypeMultiSelect
                                  id="type_ids"
                                  v-model="buildingForm.type_ids"
                                  dense
                                  outlined
                                  :rules="[$rules.length]"
                                  @input="handlePropertyTypesChange"
                                />
                              </v-col>
                            </v-row>
                            <template v-if="this.isDevelopmentSiteSelected(this.buildingForm.type_ids)">
                              <v-row dense>
                                <v-col cols="12">
                                  <label for="improvement_status_id" class="caption">Improvement Status</label>
                                  <BtnSelection
                                    id="improvement_status_id"
                                    v-model="buildingForm.improvement_status_id"
                                    ky="improvementStatuses"
                                    grow
                                    hide-details
                                    mandatory
                                  />
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-checkbox
                                  v-model="buildingForm.with_permit_approved"
                                  label="With Permit Approved"
                                  hide-details
                                />
                              </v-row>
                            </template>
                            <v-row dense>
                              <v-col cols="12" class="mt-4">
                                <div>
                                  <label class="caption required">Existing Rights of Transfer <span>*</span></label>
                                </div>
                                <div>
                                  <BtnSelection
                                    v-model="buildingForm.existing_rights_of_transfer_id"
                                    ky="existingRightsOfTransfers"
                                    grow
                                    mandatory
                                    @input="handleExistingRightsOfTransferChange"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                            <v-row dense v-if="buildingForm.existing_rights_of_transfer_id === 3">
                              <v-checkbox
                                v-model="buildingForm.is_crownland"
                                label="This is a Crownland"
                                hide-details
                              />
                            </v-row>
    
                            <ExpandPanel
                              v-if="!salesForm.is_multi_sale && address || salesForm.is_multi_sale && addresses.length"
                              label="Add or Edit Zone, Folio, Lot/Plan Number"
                              class="mt-6"
                              content-class="pl-3"
                            >
                              <template v-if="salesForm.is_multi_sale">
                                <div class="d-flex flex-column gap-sm">
                                  <v-card
                                    v-for="address in addresses"
                                    :key="address.address_detail_pid"
                                    outlined
                                  >
                                    <v-card-text v-if="individualBuildingFormEditPid === address.address_detail_pid">
                                      <ZoneVolumeLotForm
                                        v-model=individualBuildingForms[individualBuildingFormEditPid]
                                        :state_abbreviation="address.state_abbreviation"
                                      />
                                      <v-row dense>
                                        <v-btn depressed color="primary" class="ml-auto" @click="individualBuildingFormEditPid = null">Save</v-btn>
                                      </v-row>
                                    </v-card-text>
                                    <v-list-item
                                      v-else
                                      three-line
                                      @click="handleEditIndividualBuildingZoneForm(address.address_detail_pid)"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>{{ address.full_address }}</v-list-item-title>
                                        <v-list-item-subtitle v-if="address.state_abbreviation === 'VIC'" class="d-flex align-center">
                                          <span class="mr-2">Zone: </span>
                                          <div class="d-flex gap-sm">
                                            <div v-for="code in individualBuildingForms[address.address_detail_pid].zone_codes" :key="code">
                                              <v-chip small label>
                                                <v-icon
                                                  small
                                                  :color="zoneColor('zone', code, 'VIC')"
                                                  style="margin-left: 1px;"
                                                >$mdi-square</v-icon>
                                                {{ code }}
                                              </v-chip>
                                            </div>
                                          </div>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else-if="address.state_abbreviation === 'NSW'" class="d-flex align-center">
                                          <span class="mr-2">Zone: </span>
                                          <div class="d-flex gap-sm">
                                            <div v-for="code in individualBuildingForms[address.address_detail_pid].zone_codes" :key="code">
                                              <v-chip small label>
                                                <v-icon
                                                  small
                                                  :color="zoneColor('zone', code, 'NSW')"
                                                  style="margin-left: 1px;"
                                                >$mdi-square</v-icon>
                                                {{ code }}
                                              </v-chip>
                                            </div>
                                          </div>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="address.state_abbreviation === 'VIC'" class="d-flex align-center">
                                          <span class="mr-2">Overlay: </span>
                                          <div class="d-flex gap-sm">
                                            <div v-for="code in individualBuildingForms[address.address_detail_pid].overlay_codes" :key="code">
                                              <v-chip small label>
                                                <v-icon
                                                  small
                                                  :color="zoneColor('overlay', code)"
                                                  style="margin-left: 1px;"
                                                >$mdi-square</v-icon>
                                                {{ code }}
                                              </v-chip>
                                            </div>
                                          </div>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="address.state_abbreviation === 'NSW'">
                                          <div>
                                            <span>FSR: </span>
                                            <span>{{ parseFloat(individualBuildingForms[address.address_detail_pid].fsr) }} : 1</span>
                                          </div>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="address.state_abbreviation === 'NSW'">
                                          <div>
                                            <span>Lot Size: </span>
                                            <span>{{ parseFloat(individualBuildingForms[address.address_detail_pid].lot_size.value) }} {{ individualBuildingForms[address.address_detail_pid].lot_size.unit }}</span>
                                          </div>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                          <div>
                                            <span>Volume / Folio: </span>
                                            <span v-for="(item, i) in individualBuildingForms[address.address_detail_pid].volume_folios" :key="i">
                                              <span>{{ item.volume || '-' }} / {{ item.folio || '-' }}</span>
                                              <span v-if="i < individualBuildingForms[address.address_detail_pid].volume_folios.length - 1"> | </span>
                                            </span>
                                          </div>
                                          <div>
                                            <span>Lot / Section / Plan: </span>
                                            <span v-for="(item, i) in individualBuildingForms[address.address_detail_pid].lot_section_plans" :key="i">
                                              <span>{{ item.lot || '-' }} / {{ item.section || '-' }} / {{ item.plan || '-' }}</span>
                                              <span v-if="i < individualBuildingForms[address.address_detail_pid].lot_section_plans.length - 1"> | </span>
                                            </span>
                                          </div>
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-card>
                                </div>
                              </template>
                              <ZoneVolumeLotForm
                                v-else
                                v-model="buildingForm"
                                :state_abbreviation="address.state_abbreviation"
                              />
                            </ExpandPanel>
                          </v-form>
                        </v-window-item>
                        <v-window-item :value="2" eager>
                          <div v-if="individualBuildingFormEditPid === null">
                            <div v-if="salesForm.is_multi_sale">
                              <p class="font-weight-bold primary--text mb-0">Combined Values</p>
                              <v-divider class="mb-1" />
                            </div>
    
                            <BuildingForm
                              key="combined-building-form"
                              v-model="buildingForm"
                              ref="buildingForm"
                              :is-multi-sale="salesForm.is_multi_sale"
                            />
          
                            <div v-if="salesForm.is_multi_sale">
                              <p class="font-weight-bold primary--text mb-0 mt-3">Individual Values</p>
                              <v-divider class="mb-1" />
          
                              <v-card
                                v-for="(item, i) in addresses"
                                :key="i"
                                outlined
                                :disabled="loadingAddress"
                                class="d-flex align-center gap-md px-2 py-1 mb-2 mt-3 cursor-pointer"
                                @click="handleEditIndividualBuilding(item.address_detail_pid)"
                              >
                                <div>
                                  <v-list-item-title>
                                    {{ item.full_address }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ individualBuildingForms[item.address_detail_pid].building_area.value ? `${individualBuildingForms[item.address_detail_pid].building_area.value} ${individualBuildingForms[item.address_detail_pid].building_area.unit} (${dropdownItemName(individualBuildingForms[item.address_detail_pid].building_area_type_id, 'buildingAreaTypes')})` : 'Not Added Yet' }}
                                  </v-list-item-subtitle>
                                </div>
                              </v-card>
                            </div>
                          </div>
                          <div v-else>
                            <p class="font-weight-bold primary--text mb-0">Individual building for {{ fullAddressFromPid(addresses, individualBuildingFormEditPid) }}</p>
                            <v-divider class="mb-1" />

                            <BuildingForm
                              key="individual-building-form"
                              v-model="individualBuildingForm"
                              ref="individualBuildingForm"
                              :sales-type="salesType"
                            />
    
                            <v-divider class="mt-3 mb-1" />
                            <div class="d-flex align-center">
                              <v-btn text color="error" @click="handleClearIndividualBuildingForm">
                                <v-icon left>$mdi-trash-can</v-icon>
                                Clear
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn text @click="handleCancelIndividualBuildingForm">Cancel</v-btn>
                              <v-btn color="primary" depressed class="ml-2" @click="handleSaveIndividualBuildingForm('Building')">Save</v-btn>
                            </div>
                          </div>
                        </v-window-item>
                        <v-window-item :value="3" eager>
                          <div v-if="individualBuildingFormEditPid === null">
                            <div v-if="salesForm.is_multi_sale">
                              <p class="font-weight-bold primary--text mb-0">Combined Values</p>
                              <v-divider class="mb-1" />
                            </div>
                            
                            <LandForm
                              key="combined-land-form"
                              v-model="buildingForm"
                              ref="landForm"
                              :sales-type="salesType"
                              :is-multi-sale="salesForm.is_multi_sale"
                              :type-ids="buildingForm.type_ids"
                            />
          
                            <div v-if="salesForm.is_multi_sale">
                              <p class="font-weight-bold primary--text mb-0 mt-3">Individual Values</p>
                              <v-divider class="mb-1" />
          
                              <v-card
                                v-for="(item, i) in addresses"
                                :key="i"
                                outlined
                                :disabled="loadingAddress"
                                class="d-flex align-center gap-md px-2 py-1 mb-2 mt-3 cursor-pointer"
                                @click="handleEditIndividualBuilding(item.address_detail_pid)"
                              >
                                <div>
                                  <v-list-item-title>
                                    {{ item.full_address }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ individualBuildingForms[item.address_detail_pid].land_area.value ? `${individualBuildingForms[item.address_detail_pid].land_area.value} ${individualBuildingForms[item.address_detail_pid].land_area.unit} (${dropdownItemName(individualBuildingForms[item.address_detail_pid].building_area_type_id, 'buildingAreaTypes')})` : 'Not Added Yet' }}
                                  </v-list-item-subtitle>
                                </div>
                              </v-card>
                            </div>
                          </div>
                          <div v-else>
                            <p class="font-weight-bold primary--text mb-0">Individual building for {{ fullAddressFromPid(addresses, individualBuildingFormEditPid) }}</p>
                            <v-divider class="mb-1" />
    
                            <LandForm
                              key="individual-land-form"
                              v-model="individualBuildingForm"
                              ref="individualLandForm"
                              :sales-type="salesType"
                              :type-ids="buildingForm.type_ids"
                            />
    
                            <v-divider class="mt-3 mb-1" />
                            <div class="d-flex align-center">
                              <v-btn text color="error" @click="handleClearIndividualBuildingForm">
                                <v-icon left>$mdi-trash-can</v-icon>
                                Clear
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn text @click="handleCancelIndividualBuildingForm">Cancel</v-btn>
                              <v-btn color="primary" depressed class="ml-2" @click="handleSaveIndividualBuildingForm('Land')">Save</v-btn>
                            </div>
                          </div>
                        </v-window-item>
                        <v-window-item :value="4" eager>
                          <div v-if="isSalesComp">
                            <SalesForm
                              v-model="salesForm"
                              ref="salesForm"
                              :sales-type="salesType"
                              :is-multi-sale="salesForm.is_multi_sale"
                              :type-ids="buildingForm.type_ids"
                              :addresses="addresses"
                              :address="address"
                              @validity-changed="salesFormValid = $event"
                            />
                          </div>
                          <div v-else-if="isLeaseComp">
                            <LeaseForm
                              v-model="salesForm"
                              ref="salesForm"
                              :addresses="addresses"
                              :address="address"
                              :is-partial="true"
                              @validity-changed="salesFormValid = $event"
                            />
                          </div>
                        </v-window-item>
                        <v-window-item :value="41" eager>
                          <TenantRentalForm
                            ref="tenantRentalForm"
                            v-model="salesForm"
                            :addresses="salesForm.is_multi_sale ? addresses : [address]"
                            :is-comp-edit="true"
                          />
                        </v-window-item>
                        <v-window-item :value="5" eager>
                          <MarketRentalForm
                            v-model="salesForm"
                            ref="marketRentalForm"
                          />
                        </v-window-item>
                        <v-window-item :value="6" eager>
                          <CampaignForm
                            v-model="campaignForm"
                            ref="campaignForm"
                            @validity-changed="campaignFormValid = $event"
                          />
                        </v-window-item>
                        <v-window-item :value="7" eager>
                          <p class="font-weight-bold primary--text mb-0">Images & Files</p>
                          <v-divider></v-divider>
    
                          <FileUploadForm v-model="salesForm" class="mt-2"/>
    
                          <v-form ref="extraForm" v-model="extraFormValid" class="mt-4">
                            <p class="font-weight-bold primary--text mb-0">Comments</p>
                            <v-divider></v-divider>
    
                            <div class="mt-1">
                              <label for="extra_info" class="caption">Comments(Optional)</label>
                              <v-textarea
                                v-model="salesForm.extra_info"
                                id="extra_info"
                                rows="6"
                                outlined
                                hide-details
                                :clearable="false"
                              />
                            </div>
                          </v-form>
                        </v-window-item>
                        <v-window-item :value="8" eager>
                          <div v-if="sourceForms.length === 0 && sourceFormEditInd === -1" class="mb-n2">
                            <v-alert
                              border="left"
                              text
                              dense
                              type="warning"
                            >
                              <div>There are no source added yet</div>
                              <div>You need to add at least one source</div>
                            </v-alert>
                          </div>
                          <div v-else-if="sourceFormEditInd === -1">
                            <v-card v-for="(item, i) in sourceForms" :key="i" outlined class="d-flex align-center gap-md px-4 py-1 mb-2">
                              <div>
                                <v-list-item-title>
                                  {{ dropdownItemName(item.source_type_id, 'sourceOfInfos') }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ item.images.length ?? 'No' }} images
                                </v-list-item-subtitle>
                              </div>
                              <v-list-item-action class="flex-row align-center gap-sm ml-auto">
                                <v-btn icon @click=handleEditSource(i)>
                                  <v-icon small>$mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon>
                                  <v-icon small>$mdi-trash-can</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-card>
                          </div>
    
                          <div v-if="sourceFormEditInd !== -1">
                            <SourceForm
                              v-model="sourceForm"
                              ref="sourceForm"
                            />
                            <div class="d-flex align-center">
                              <v-btn v-if="sourceForms.length !== sourceFormEditInd" text color="error" @click="handleDeleteSource">
                                <v-icon left>$mdi-trash-can</v-icon>
                                Delete
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn text @click="handleCancelSourceForm">Cancel</v-btn>
                              <v-btn color="primary" depressed class="ml-2" @click="handleSaveSourceForm">Save</v-btn>
                            </div>
                          </div>
    
                          <div
                            v-if="sourceForms.length < 3 && sourceFormEditInd === -1"
                            class="text-center mt-1"
                          >
                            <v-btn
                              depressed
                              @click="handleAddMoreSource"
                            ><v-icon left>$mdi-plus</v-icon>
                              Add Source
                            </v-btn>
                          </div>

                          <div v-if="!isCKC" class="mt-4">
                            <div>Reviewers (optional)</div>
                            <v-autocomplete
                              v-model="salesForm.reviewer_ids"
                              :items="reviewers"
                              outlined
                              hide-details
                              chips
                              item-text="full_name"
                              item-value="id"
                              multiple
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  close
                                  :label="false"
                                  class="my-1"
                                  @click="data.select"
                                  @click:close="
                                    const index = salesForm.reviewer_ids.indexOf(data.item.id)
                                    if (index >= 0) salesForm.reviewer_ids.splice(index, 1)
                                  "
                                >
                                  <v-avatar
                                    left
                                    color="primary"
                                    size="22"
                                  >
                                    <span class="white--text">{{ getInitials(data.item.full_name) }}</span>
                                  </v-avatar>
                                  {{ data.item.full_name }}
                                </v-chip>
                              </template>
                              <template v-slot:item="data">
                                <v-list-item-avatar>
                                  <v-avatar
                                    color="primary"
                                    size="32"
                                  >
                                    <span class="white--text">{{ getInitials(data.item.full_name) }}</span>
                                  </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title v-html="data.item.full_name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-card-text>
                  </div>
                </v-card>
    
                <div class="mt-2 d-flex justify-center gap-md">
                  <v-btn v-if="stepper !== 1" depressed @click="handlePrevious">
                    <v-icon left small>$mdi-arrow-left</v-icon>
                    Previous
                  </v-btn>
                  <v-btn depressed color="primary" :loading="saving" @click="handleNext">
                    {{ stepper === 8 ? 'Submit' : 'Next' }}
                    <v-icon v-if="stepper !== 8" right small>$mdi-arrow-right</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <v-card width="400" min-width="340">
            <CompMapPreview
              :sales-type="salesType"
              :is-multi-sale="salesForm.is_multi_sale"
              :address="salesForm.is_multi_sale ? addresses : address"
              :form-data="{
                salesForm,
                buildingForm,
                individualBuildingForms
              }"
              :address-polygons="addressPolygons"
              :is-edit="true"
              :loading="loading"
            >
            </CompMapPreview>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import {
  alphabeticalIndex,
  getInitials
} from '@/helper/utils'

import BuildingForm from '@/components/forms/BuildingForm.vue'
import LandForm from '@/components/forms/LandForm.vue'
import SalesForm from '@/components/forms/SalesForm.vue'
import TenantRentalForm from '@/components/forms/TenantRentalForm.vue'
import MarketRentalForm from '@/components/forms/MarketRentalForm.vue'
import LeaseForm from '@/components/forms/LeaseForm.vue'
import CampaignForm from '@/components/forms/CampaignForm.vue'
import FileUploadForm from '@/components/forms/FileUploadForm.vue'
import CompMapPreview from '@/components/CompMapPreview.vue'
import SourceForm from '@/components/forms/SourceForm.vue'
import PropertyTypeMultiSelect from '@/components/PropertyTypeMultiSelect'
import ZoneVolumeLotForm from '@/components/forms/ZoneVolumeLotForm.vue'

import {
  defaultSalesForm,
  defaultLeaseForm,
  defaultAreaForm,
  defaultBuildingForm,
  defaultCampaignForm,
  defaultSourceForm,
  defaultDwellingForm
} from '@/@propedia/default_forms'

export default {
  props: ['value', 'compId', 'salesType', 'showReviewers'],
  components: {
    BuildingForm,
    LandForm,
    SalesForm,
    TenantRentalForm,
    MarketRentalForm,
    LeaseForm,
    CampaignForm,
    FileUploadForm,
    CompMapPreview,
    SourceForm,
    PropertyTypeMultiSelect,
    ZoneVolumeLotForm
  },

  data() {
    return {
      alphabeticalIndex,
      getInitials,

      stepper: 1,
      address: null,
      addresses: [],
      loadingAddress: false,
      addressPolygons: null,

      addressFormValid: false,
      landFormValid: false,
      extraFormValid: false,
      salesFormValid: false,

      salesForm: structuredClone(this.$route.query.sales_type === 'salesComp' ? defaultSalesForm : defaultLeaseForm),
      buildingForm: structuredClone({
        ...defaultBuildingForm,
        ...defaultAreaForm,
        dwellings: [
          structuredClone(defaultDwellingForm),
          structuredClone(defaultDwellingForm),
          structuredClone(defaultDwellingForm)
        ]
      }),
      individualBuildingForm: structuredClone({
        ...defaultBuildingForm,
        ...defaultAreaForm,
        dwellings: [
          structuredClone(defaultDwellingForm),
          structuredClone(defaultDwellingForm),
          structuredClone(defaultDwellingForm)
        ]
      }),
      individualBuildingForms: {},
      individualBuildingFormEditPid: null,
      campaignForm: structuredClone(defaultCampaignForm),
      sourceForms: [],
      sourceForm: null,
      sourceFormEditInd: -1,

      loading: false,
      saving: false,
      loadingZone: false,
      loadingOverlay: false,

      uploadedImages: [],
      uploadedFiles: [],

      showIndividualLease: false,
      individualLeaseTab: 0,
      showRent: false,
      sourceDlg: false,

      steps: [
        { id: 1, text: 'Address', icon: '$mdi-numeric1box' },
        { id: 2, text: 'Building', icon: '$mdi-numeric2box' },
        { id: 3, text: 'Land', icon: '$mdi-numeric3box' },
        { id: 4, text: '', icon: '$mdi-numeric4box' },
        { id: 41, text: 'Rental Info, Lease(s)', icon: '$mdi-circle-small', parent_id: 4 },
        { id: 5, text: 'Market Rental Analysis', icon: '$mdi-numeric5box' },
        { id: 6, text: 'Campaign', icon: '$mdi-numeric6box' },
        { id: 7, text: 'Files & Comments', icon: '$mdi-numeric7box' },
        { id: 8, text: 'Source & Reviewers', icon: '$mdi-numeric8box' }
      ],

      reviewers: []
    }
  },
  
  computed: {
    ...mapState('property', [
      'compDetail',
      'gnafProperties'
    ]),
    ...mapState('dropdown', [
      'dropdowns'
    ]),
    ...mapGetters(['isSalesComp', 'isLeaseComp']),
    ...mapGetters('dropdown', [
      'dropdownItemName',
      'isDevelopmentSiteSelected'
    ]),
    ...mapGetters('zoning_and_overlay', [
      'zoneColor'
    ]),
    ...mapGetters('address', [
      'fullAddressFromPid'
    ]),
    ...mapGetters('auth', [
      'isCKC'
    ])
  },
  
  watch: {
    value(v) {
      if(v) {
        this.stepper = 1

        setTimeout(() => {
          this.getItemDetail()
        }, 300)
      }
    }
  },
  
  mounted() {
    this.getReviewers()
  },

  methods: {
    ...mapActions('app', ['notifySuccess', 'notifyError']),
    ...mapActions('property', [
      'getCompDetails',
      'getSourcesOfInfo',
      'getPropertyPolygons'
    ]),
    ...mapMutations('property', [
      'SET_COMPID_FOR_EDIT',
      'SET_EDIT_MODAL'
    ]),
    async getItemDetail() {
      this.loading = true

      this.sourceForms = []
      this.salesForm = structuredClone(this.$route.query.sales_type === 'salesComp' ? defaultSalesForm : defaultLeaseForm),
      this.buildingForm = structuredClone({
        ...defaultBuildingForm,
        ...defaultAreaForm
      })

      try {
        await this.getCompDetails({
          salesType: this.salesType,
          compId: this.compId
        })
        const v = this.compDetail
        let gnaf_property_pids

        if (v.is_multi_sale) {
          this.addresses = v.individuals.map((p) => p.address)
          gnaf_property_pids = this.addresses.map((v) => v.gnaf_property_pid)
        } else {
          this.address = v.address
          gnaf_property_pids = [this.address.gnaf_property_pid]
        }

        this.getPropertyPolygons({
          state_abbreviation: v.state_abbreviation,
          gnaf_property_pids
        }).then(() => {
          this.addressPolygons = this.gnafProperties.map((v, i) => {
            let gnaf_property_pid = null
  
            if (v.state_abbreviation === 'VIC') {
              gnaf_property_pid = +v.pfi
            } else if (v.state_abbreviation === 'NSW') {
              gnaf_property_pid = +v.propid
            }

            return {
              geom: v.geom,
              gnaf_property_pid,
              label: alphabeticalIndex(i, true)
            }
          })
        })

        // Sales form
        if (this.isSalesComp) {
          for (const k in defaultSalesForm) {
            this.salesForm[k] = v[k]
          }

          this.salesForm.vendor_ids = v.vendor_ids || []
          this.salesForm.purchaser_ids = v.purchaser_ids || []
          this.salesForm.leases = v.leases
          this.salesForm.is_add_lease = v.leases.length > 0
        } else {
          for (const k in defaultLeaseForm) {
            this.salesForm[k] = v[k]
          }

          this.salesForm.partial_building_area = {
            value: v.partial_building_area,
            unit: v.partial_building_area_unit
          }
          this.salesForm.lease_terms = {
            value: v.lease_terms,
            unit: v.lease_terms_unit
          }
          if (v.lease_options === null || v.lease_options.length === 0) {
            this.salesForm.lease_options = [null]
          }
        }

        // Building and land
        for (const k in defaultBuildingForm) {
          this.buildingForm[k] = v.building[k]
        }
        if (!v.building.ratings) {
          this.buildingForm.ratings = []
        }
        this.buildingForm.lot_size = {
          value: v.building.lot_size,
          unit: v.building.lot_size_unit || 'm2'
        }
        this.buildingForm.building_area = {
          value: v.building.building_area,
          unit: v.building.building_area_unit || 'SQM'
        }
        this.buildingForm.land_area = {
          value: v.building.land_area,
          unit: v.building.land_area_unit || 'SQM'
        }
        this.buildingForm.footprint_area = {
          value: v.building.footprint_area,
          unit: v.building.footprint_area_unit || 'SQM'
        }
        this.buildingForm.hardstand_area = {
          value: v.building.hardstand_area,
          unit: v.building.hardstand_area_unit || 'SQM'
        }
        this.buildingForm.developable_area = {
          value: v.building.developable_area,
          unit: v.building.developable_area_unit || 'SQM'
        }
        this.buildingForm.total_sellable_area = {
          value: v.building.total_sellable_area,
          unit: v.building.total_sellable_area_unit || 'SQM'
        }
        this.buildingForm.gross_floor_area = {
          value: v.building.gross_floor_area,
          unit: v.building.gross_floor_area_unit || 'SQM'
        }
        this.buildingForm.building_height = {
          value: v.building.building_height,
          unit: v.building.building_height_unit || 'Meters'
        }
        
        // Area form
        for (let i = 0; i < 7; i++) {
          this.buildingForm[`area_${i + 1}_type_id`] = v.building[`area_${i + 1}_type_id`]
          this.buildingForm[`area_${i + 1}`] = {
            value: v.building[`area_${i + 1}`],
            unit: v.building[`area_${i + 1}_unit`] || 'SQM'
          }
        }

        // Dwelling form
        this.buildingForm.dwellings = v.building.dwellings

        // Campaign
        for (const k in defaultCampaignForm) {
          this.campaignForm[k] = v.campaign ? v.campaign[k] : defaultCampaignForm[k]
        }

        // Source forms
        this.getSourcesOfInfo(v.source_of_info_ids).then((res) => {
          res.data.items.forEach((item) => {
            const form = {}

            for (const k in defaultSourceForm) {
              form[k] = item[k]
            }

            if (!item.images) {
              form.images = []
            }

            this.sourceForms = [...this.sourceForms, structuredClone(form)]
          })
        })

        if (this.salesForm.is_multi_sale) {
          this.addresses.forEach((address) => {
            const individual = v.individuals.find((v) => v.address_detail_pid === address.address_detail_pid)

            this.individualBuildingForms[address.address_detail_pid] = structuredClone(individual.building)
            if (!this.individualBuildingForms[address.address_detail_pid].ratings) {
              this.individualBuildingForms[address.address_detail_pid].ratings = []
            }
            this.individualBuildingForms[address.address_detail_pid].lot_size = {
              value: individual.building.lot_size,
              unit: individual.building.lot_size_unit || 'm2'
            }
            this.individualBuildingForms[address.address_detail_pid].building_area = {
              value: individual.building.building_area,
              unit: individual.building.building_area_unit || 'SQM'
            }
            this.individualBuildingForms[address.address_detail_pid].land_area = {
              value: individual.building.land_area,
              unit: individual.building.land_area_unit || 'SQM'
            }
            this.individualBuildingForms[address.address_detail_pid].footprint_area = {
              value: individual.building.footprint_area,
              unit: individual.building.footprint_area_unit || 'SQM'
            }
            this.individualBuildingForms[address.address_detail_pid].hardstand_area = {
              value: individual.building.hardstand_area,
              unit: individual.building.hardstand_area_unit || 'SQM'
            }
            this.individualBuildingForms[address.address_detail_pid].developable_area = {
              value: individual.building.developable_area,
              unit: individual.building.developable_area_unit || 'SQM'
            }
            this.individualBuildingForms[address.address_detail_pid].total_sellable_area = {
              value: individual.building.total_sellable_area,
              unit: individual.building.total_sellable_area_unit || 'SQM'
            }
            this.individualBuildingForms[address.address_detail_pid].gross_floor_area = {
              value: individual.building.gross_floor_area,
              unit: individual.building.gross_floor_area_unit || 'SQM'
            }
            this.individualBuildingForms[address.address_detail_pid].building_height = {
              value: individual.building.building_height,
              unit: individual.building.building_height_unit || 'Meters'
            }

            // Area form
            for (let i = 0; i < 7; i++) {
              this.individualBuildingForms[address.address_detail_pid][`area_${i + 1}_type_id`] = individual.building[`area_${i + 1}_type_id`]
              this.individualBuildingForms[address.address_detail_pid][`area_${i + 1}`] = {
                value: individual.building[`area_${i + 1}`],
                unit: individual.building[`area_${i + 1}_unit`] || 'SQM'
              }
            }

            // Dwelling form
            this.individualBuildingForms[address.address_detail_pid].dwellings = individual.building.dwellings
          })
        }

        if (v.images) {
          this.salesForm.images = v.images.map((v, i) => {
            return {
              id: i,
              path: v.path,
              originalname: v.originalname
              // imgId: v.id
            }
          })
          // this.salesForm.existingImageIds = v.images.map((v) => v.id)
        } else {
          this.salesForm.images = []
        }
        // if (v.docs) {
        //   this.filesForm.pdfs = v.docs.map((v, i) => {
        //     return {
        //       id: i,
        //       type: v.mimetype,
        //       url: v.path,
        //       label: v.label,
        //       docId: v.id
        //     }
        //   })
        //   this.filesForm.existingDocIds = v.docs.map((v) => v.id)
        // } else {
        //   this.filesForm.pdfs = []
        // }

        this.salesForm.sale_price_gst_excl = this.buildingForm.gst_included ? Math.floor(this.salesForm.sale_price * 10 / 11) : this.salesForm.sale_price
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    isFormValid(ind) {
      switch(ind) {
        case 1:
          return this.landBuildingFormValid
        case 2:
          return this.salesFormValid
        case 3:
          return true
        case 4:
          return this.extraFormValid
      }
    },
    handlePrevious() {
      if (this.stepper === 5 && this.salesForm.tenure_type_id === 2) {
        this.stepper = 41
      } else if (this.stepper === 41) {
        this.stepper = 4
      } else if (this.stepper > 1) {
        this.stepper -= 1
      }
    },
    handleNext() {
      if (this.stepper === 1) { // Address and property types
        if (this.$refs.form1.validate()) {
          this.stepper += 1
        }
      } else if (this.stepper === 2) { // Building form
        if (this.$refs.buildingForm.$refs.form.validate()) {
          this.stepper += 1
        }
      } else if (this.stepper === 3) { // Land form
        if (this.$refs.landForm.$refs.form.validate()) {
          this.stepper += 1
        }
      } else if (this.stepper === 4) { // Sales form
        if (this.$refs.salesForm.$refs.form.validate()) {
          if (this.salesForm.tenure_type_id === 2) {
            this.stepper = 41
          } else {
            this.stepper += 1
          }
        }
      } else if (this.stepper === 41) { // Rental info, leases form
        if (this.$refs.tenantRentalForm.$refs.form.validate()) {
          this.stepper = 5
        }
      } else if (this.stepper === 5) { // Market rental analysis form
        if (this.$refs.marketRentalForm.$refs.form.validate()) {
          this.stepper += 1
        }
      } else if (this.stepper === 6) { // Campaign form
        if (this.$refs.campaignForm.$refs.form.validate()) {
          this.stepper += 1
        }
      } else if (this.stepper === 7) {
        this.stepper += 1
      } else if (this.stepper === 8) { // Source form
        this.handleSubmit()
      }
    },
    async handleSubmit() {
      if (!this.$refs.form1.validate()) {
        this.stepper = 1; return
      } else if (!this.$refs.buildingForm || !this.$refs.buildingForm.$refs.form.validate()) {
        this.stepper = 2; return
      } else if (!this.$refs.landForm || !this.$refs.landForm.$refs.form.validate()) {
        this.stepper = 3; return
      } else if (!this.$refs.salesForm || !this.$refs.salesForm.$refs.form.validate()) {
        this.stepper = 4; return
      } else if (!this.$refs.marketRentalForm || !this.$refs.marketRentalForm.$refs.form.validate()) {
        this.stepper = 5; return
      } else if (!this.$refs.campaignForm || !this.$refs.campaignForm.$refs.form.validate()) {
        this.stepper = 6; return
      } else if (this.sourceForms.length < 1) {
        this.stepper = 8; return
      }

      try {
        this.saving = true

        const parseBuildingForm = (buildingForm) => {
          return {
            ...buildingForm,
            building_area: buildingForm.building_area.value,
            building_area_unit: buildingForm.building_area.unit,
            footprint_area: buildingForm.footprint_area.value,
            footprint_area_unit: buildingForm.footprint_area.unit,
            land_area: buildingForm.land_area.value,
            land_area_unit: buildingForm.land_area.unit,
            hardstand_area: buildingForm.hardstand_area.value,
            hardstand_area_unit: buildingForm.hardstand_area.unit,
            developable_area: buildingForm.developable_area.value,
            developable_area_unit: buildingForm.developable_area.unit,
            area_1: buildingForm.area_1.value,
            area_1_unit: buildingForm.area_1.unit,
            area_2: buildingForm.area_2.value,
            area_2_unit: buildingForm.area_2.unit,
            area_3: buildingForm.area_3.value,
            area_3_unit: buildingForm.area_3.unit,
            area_4: buildingForm.area_4.value,
            area_4_unit: buildingForm.area_4.unit,
            area_5: buildingForm.area_5.value,
            area_5_unit: buildingForm.area_5.unit,
            area_6: buildingForm.area_6.value,
            area_6_unit: buildingForm.area_6.unit,
            area_7: buildingForm.area_7.value,
            area_7_unit: buildingForm.area_7.unit,
            total_sellable_area: buildingForm.total_sellable_area.value,
            total_sellable_area_unit: buildingForm.total_sellable_area.unit,
            gross_floor_area: buildingForm.gross_floor_area.value,
            gross_floor_area_unit: buildingForm.gross_floor_area.unit,
            building_height: buildingForm.building_height.value,
            building_height_unit: buildingForm.building_height.unit
          }
        }
        const parsedIndividualBuildingForms = () => {
          let ret = {}

          if (this.salesForm.is_multi_sale) {
            this.addresses.forEach((address) => {
              ret[address.address_detail_pid] = parseBuildingForm(this.individualBuildingForms[address.address_detail_pid])
            })
          }

          return ret
        }

        await api.put(`/comps/${this.compId}`, {
          salesType: this.salesType,
          address_detail_pid: this.address?.address_detail_pid,
          address_detail_pids: this.addresses.map((a) => a.address_detail_pid),
          salesForm: this.isSalesComp ? this.salesForm : {
            ...this.salesForm,
            lease_terms: this.salesForm.lease_terms.value,
            lease_terms_unit: this.salesForm.lease_terms.unit,
            partial_building_area: this.salesForm.partial_building_area.value,
            partial_building_area_unit: this.salesForm.partial_building_area.unit
          },
          buildingForm: parseBuildingForm(this.buildingForm),
          individualBuildingForms: parsedIndividualBuildingForms(),
          campaignForm: this.campaignForm,
          sourceForms: this.sourceForms
        })

        this.$emit('updated')
        this.notifySuccess({
          title: 'Successfully updated!',
          subtitle: 'The record successfully updated!'
        })
      } catch (e) {
        if (e.response?.data?.message) {
          this.notifyError({
            subtitle: e.response.data.message
          })
        } else {
          this.notifyError({
            subtitle: ''
          })
        }
      } finally {
        this.saving = false
      }
    },
    handleExistingRightsOfTransferChange(v) {
      if (this.salesForm.is_multi_sale) {
        this.addresses.forEach(address => {
          this.individualBuildingForms[address.address_detail_pid].existing_rights_of_transfer_id = v
        })
      }
    },
    handlePropertyTypesChange(v) {
      if (this.salesForm.is_multi_sale) {
        this.addresses.forEach(address => {
          this.individualBuildingForms[address.address_detail_pid].type_ids = v
        })
      }
      if (!this.isDevelopmentSiteSelected(this.buildingForm.type_ids)) { // If not (vacant land / development site)
        this.buildingForm.number_of_approved_childcare_places = null
        this.buildingForm.number_of_approved_rooms = null
        this.buildingForm.total_sellable_area = { value: null, unit: 'SQM' }
        this.buildingForm.permit_application_number = null
        this.buildingForm.gross_floor_area = { value: null, unit: 'SQM' }
        this.buildingForm.building_height = { value: null, unit: 'Meters' }
        this.buildingForm.existing_use_type_ids = []
        this.buildingForm.number_of_floors = null
        this.buildingForm.number_of_basement_floors = null
        this.buildingForm.parking_type_ids = []
        this.buildingForm.number_of_car_spaces = null
        this.buildingForm.dwellings = [
          structuredClone(defaultDwellingForm),
          structuredClone(defaultDwellingForm),
          structuredClone(defaultDwellingForm)
        ]
      }
    },
    handleAddMoreSource() {
      this.sourceForm = structuredClone(defaultSourceForm)
      this.sourceFormEditInd = this.sourceForms.length
    },
    handleSaveSourceForm() {
      if (this.$refs.sourceForm.$refs.form.validate()) {
        if (this.sourceFormEditInd === this.sourceForms.length) { // Add new
          this.sourceForms = [...this.sourceForms, structuredClone(this.sourceForm)]
        } else { // Update
          this.sourceForms[this.sourceFormEditInd] = structuredClone(this.sourceForm)
        }

        this.sourceFormEditInd = -1
      }
    },
    handleCancelSourceForm() {
      this.sourceFormEditInd = -1
    },
    handleEditSource(i) {
      this.sourceForm = structuredClone(this.sourceForms[i])
      this.sourceFormEditInd = i
    },
    handleDeleteSource() {
      this.sourceForms.splice(this.sourceFormEditInd, 1)
      this.sourceFormEditInd = -1
    },
    // Individual Building Form
    handleEditIndividualBuilding(address_detail_pid) {
      this.individualBuildingForm = structuredClone(this.individualBuildingForms[address_detail_pid])
      this.individualBuildingFormEditPid = address_detail_pid
    },
    handleClearIndividualBuildingForm() {
      this.individualBuildingForm = structuredClone({
        ...defaultBuildingForm,
        ...defaultAreaForm
      })
    },
    handleCancelIndividualBuildingForm() {
      this.individualBuildingFormEditPid = null
    },
    handleSaveIndividualBuildingForm(type) {
      if (this.$refs[`individual${type}Form`].$refs.form.validate()) {
        this.individualBuildingForms[this.individualBuildingFormEditPid] = structuredClone(this.individualBuildingForm)
        this.individualBuildingFormEditPid = null
      }
    },
    handleEditIndividualBuildingZoneForm(address_detail_pid) {
      this.individualBuildingFormEditPid = address_detail_pid
    },
    closeEdit() {
      this.$emit('close')
      this.SET_COMPID_FOR_EDIT(null)
      this.SET_EDIT_MODAL(false)
    },
    getReviewers() {
      api.get('/users/reviewers').then((res) => {
        this.reviewers = res.data.users
      })
    },
  }
}
</script>