<template>
  <div :class="{'d-flex align-center': inline}" v-bind="$attrs">
    <div
      v-if="label"
      class="caption white-space-no-wrap"
      :class="{'mr-1': inline}"
      :style="`width: ${labelWidth ? labelWidth + 'px' : ''}`"
    >{{ label }}</div>
    
    <div>
      <v-icon v-if="!unlocked" small>$mdi-lock</v-icon>
      <template v-else>
        <slot name="value">
          <div
            v-if="hasUnit"
            :class="{'white-space-no-wrap': noWrap}"
            :style="customStyle"
          >
            <template v-if="val">
              <span>{{ val }}</span>
              <span class="caption ml-1">{{ unit }}</span>
            </template>
            <span v-else>-</span>
          </div>
          <div
            v-else
            :class="{'white-space-no-wrap': noWrap}"
            :style="customStyle"
          >{{ val ?? '-' }}</div>
        </slot>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formattedDate } from '@/helper/date'

export default {
  props: {
    type: {
      type: String,
      default: 'normal'
    },
    value: {
      type: [String, Number, Array]
    },
    ky: {
      type: String
    },
    hasUnit: ({
      type: Boolean,
      default: false
    }),
    label: {
      type: String
    },
    unlocked: {
      type: Boolean,
      default: true
    },
    inline: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String
    },
    noWrap: {
      type: Boolean,
      default: true
    },
    customStyle: {
      type: String
    }
  },
  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapGetters('dropdown', ['dropdownItemName']),
    val() {
      const v = this.hasUnit ? this.value[0] : this.value

      switch(this.type) {
        case 'number':
          return v ? this.$options.filters.number(v) : null
        case 'price':
          return v ? this.$options.filters.currency(this.$options.filters.number(v)) : null
        case 'dropdown':
          return this.dropdownItemName(v, this.ky)
        case 'percentage':
          return v ? `${this.$options.filters.percentage(this.$options.filters.number(v))}` : null
        case 'month-format':
          return formattedDate(v, 'MMMM yyyy')
        case 'date-format':
          return formattedDate(v, 'd MMM yyyy')
        default:
          return v
      }
    },
    unit() {
      return this.value[1]
    }
  }
}
</script>