<template>
  <v-dialog
    :value="value"
    width="800"
    persistent
    no-click-animation
    scrollable
    :retain-focus="false"
  >
    <v-card>
      <v-card-title class="py-1">
        Add or hide columns
        <v-icon class="ml-auto" @click="cancel">$mdi-close</v-icon>
      </v-card-title>

      <v-divider />

      <v-card-text style="height: 540px">
        <div v-for="attributeGroup in attributeGroups" :key="attributeGroup.value" class="mt-2">
          <div class="d-flex flex-column" style="width: fit-content;">
            <div class="subtitle-1 pr-4">{{ attributeGroup.label }}</div>
            <v-divider class="mb-1"></v-divider>
          </div>
          <div class="d-flex flex-wrap gap-column-lg pl-2">
            <v-checkbox
              v-for="allColumn in allColumnsBySalesType(salesType).filter((column) => column.group === attributeGroup.value)"
              :key="allColumn.id"
              :value="allColumn.id"
              v-model="customColumnIds"
              :label="allColumn.text"
              color="primary"
              hide-details
              dense
              :ripple="false"
              class="mt-0"
            ></v-checkbox>
          </div>
        </div>
      </v-card-text>
      
      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="cancel"
        >Close</v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="loading"
          @click="submit"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  ADDRESS,
  SALES,
  BUILDING,
  ANALYSIS,
  ATTACHMENT,
  SOURCE
} from '@/@propedia/constants/attribute_groups'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    salesType: {
      type: String,
      default: 'salesComp'
    },
    isShortlist: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      loading: false,
      customColumnIds: [],
      attributeGroups: [
        {
          label: 'Address',
          value: ADDRESS
        }, {
          label: 'Sales',
          value: SALES
        }, {
          label: 'Building',
          value: BUILDING
        }, {
          label: 'Analysis',
          value: ANALYSIS
        }, {
          label: 'Attachment',
          value: ATTACHMENT
        }, {
          label: 'Source of info',
          value: SOURCE
        }
      ]
    }
  },
  computed: {
    ...mapState('settings', ['userColumns', 'userShortlistColumns']),
    ...mapGetters('settings', ['allColumnsBySalesType'])
  },
  watch: {
    value(v) {
      if (v) {
        this.customColumnIds = this.isShortlist ? this.userShortlistColumns[this.salesType].map((col) => col.id) : this.userColumns[this.salesType].map((col) => col.id)
      }
    }
  },
  methods: {
    ...mapActions('settings', ['getUserColumns', 'updateUserColumns']),
    ...mapMutations('property', [
      'SET_COLUMN_EDIT_MODAL'
    ]),
    cancel() {
      this.SET_COLUMN_EDIT_MODAL(false)
    },
    async submit() {
      try {
        this.loading = true

        await this.updateUserColumns({
          salesType: this.salesType,
          columnIds: this.customColumnIds,
          isShortlist: this.isShortlist
        })

        await this.getUserColumns()

        this.SET_COLUMN_EDIT_MODAL(false)
      } catch {
        // do nothing
      } finally {
        this.loading = false
      }
    }
  }
}
</script>