<template>
  <div>
    <v-menu
      v-model="menu1"
      offset-y
      :nudge-top="hideDetails ? 0 : 16"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-select
          v-bind="{ ...$attrs, ...attrs }"
          v-on="on"
          multiple
          placeholder="Property Types"
          readonly
          item-value="id"
          item-text="name"
          :hide-details="hideDetails"
          :items="dropdowns.buildingTypes"
          :value="value"
        >
          <template v-slot:selection="data">
            <v-chip
              v-if="
                data.item.parent_id ||
                (data.item.parent_id === null && arrayUtil.howManyInArray(value, childrenIds(data.item)) === 0)
              "
              v-bind="data.attrs"
              :input-value="data.selected"
              small
              label
              @click="menu1 = true"
            >
              <template v-if="data.item.parent_id">
                <span>{{ parentTypeName(data.item.id) }} > {{ data.item.name }}</span>
              </template>
              <span v-else>{{ data.item.name }}</span>
            </v-chip>
          </template>
        </v-select>
      </template>

      <v-sheet class="">
        <div v-if="modalBrowsing" class="text-right px-2 py-1">
          <span
            class="primary--text caption text-decoration-underline cursor-pointer"
            @click="menu = true"
          >
            Browse All Property Types
          </span>
        </div>
        <v-divider v-if="modalBrowsing"></v-divider>
        <div class="d-flex">
          <v-list-item-group
            v-if="parsedBuildingTypes"
            v-model="selectedParent"
            active-class="primary--text"
            mandatory
          >
            <v-list
              nav
              dense
              :ripple="false"
              width="350"
            >
              <v-list-item
                v-for="(parent) in parsedBuildingTypes"
                :key="parent.id"
                :value="parent"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="value.includes(parent.id)" @change="handleSelect($event, parent)"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ parent.name }}
                      <v-chip v-if="childrenCount(parent)" small :color="active ? 'primary' : ''" :label="false" class="ml-1">
                        {{ childrenCount(parent) }}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>$mdi-chevron-right</v-icon>
                  </v-list-item-icon>
                </template>
              </v-list-item>
            </v-list>
          </v-list-item-group>

          <div
            v-if="selectedParent"
            class="flex-grow-1 pt-2 pr-3"
            style="max-height: 420px; overflow-y: auto;"
          >
            <v-checkbox
              v-for="(child) in selectedParent.children"
              :key="child.id"
              :label="child.name"
              :input-value="value.includes(child.id)"
              hide-details
              class="pt-0"
              @change="handleSelect($event, child)"
            ></v-checkbox>
          </div>
        </div>
      </v-sheet>
    </v-menu>

    <v-dialog
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      scrollable
      persistent
      no-click-animation
      max-width="1600"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between py-1">
          <span>Select Property Types</span>
          <v-icon class="mr-n3" @click="menu = false">$mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="parsedBuildingTypes" class="">
          <div class="d-flex flex-column flex-wrap gap-md mt-1" style="max-height: 620px;">
            <div v-for="(parentType, i) in parsedBuildingTypes" :key="i">
              <v-checkbox
                :ripple="false"
                hide-details
                :label="parentType.name"
                class="mt-0 pt-0"
                :input-value="value.includes(parentType.id)"
                @change="handleSelect($event, parentType)"
              ></v-checkbox>
              <v-divider class="mb-1" style="background-color: rgba(0, 0, 0, 0.4);"></v-divider>
              <div v-for="(child, j) in parentType.children" :key="j">
                <v-checkbox
                  :ripple="false"
                  hide-details
                  :label="child.name"
                  class="mt-0 pt-0 child-checkbox"
                  :input-value="value.includes(child.id)"
                  @change="handleSelect($event, child)"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import arrayUtil from '@/helper/array'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    id: {
      type: String
    },
    limited: {
      type: Boolean
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    modalBrowsing: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      arrayUtil,

      menu: false,
      menu1: false,
      // selectedParentId: null,
      selectedParent: null
    }
  },
  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapGetters('dropdown', [
      'parentTypeName',
      'dropdownItemName',
      'dropdownItem',
      'childrenIds'
    ]),
    local() {
      return this.value
    },
    parsedBuildingTypes() {
      if (this.dropdowns.buildingTypes) {
        const parentTypes = this.dropdowns.buildingTypes
          .filter((v) => v.parent_id === null)

        return parentTypes.map((p) => {
          return {
            ...p,
            children: this.dropdowns.buildingTypes.filter((v) => v.parent_id === p.id)
          }
        })
      } else {
        return null
      }
    }
  },
  methods: {
    childrenCount(parent) {
      return parent.children.filter((v) => this.local.includes(v.id)).length
    },
    handleSelect(v, type) {
      if (v) {
        if (type.parent_id) { // If child checked, parent should be automatically checked
          this.$emit('input', arrayUtil.uniqueValues([...this.value, type.id, type.parent_id]))
        } else { // If parent is checked
          this.$emit('input', [...this.value, type.id])
        }
      } else {
        if (type.parent_id) { // If child unchecked, uncheck parent if no children
          const children = this.dropdowns.buildingTypes.filter((v) => v.parent_id === type.parent_id)
          const count = this.value.filter((v) => children.map((v) => v.id).includes(v)).length

          if (count === 1) {
            this.remove([type.id, type.parent_id])
          } else {
            this.remove([type.id])
          }
        } else { // If parent unchecked, uncheck all children and itself
          const children = this.dropdowns.buildingTypes.filter((v) => v.parent_id === type.id)

          this.remove([type.id, ...children.map((v) => v.id)])
        }
      }
    },
    remove(ids) {
      this.$emit('input', this.value.filter((v) => !ids.includes(v)))
    }
  }
}
</script>

<style lang="scss">
.child-checkbox.v-input--checkbox {
  max-width: 180px;
  .v-input__slot {
    align-items: start;
  }
}
</style>