<template>
  <div class="text-center">
    <v-btn
      v-for="(btn, i) in btnList"
      :key="i"
      :color="active(btn) ? 'primary' : ''"
      :to="{ name: $route.name, query: { ...$route.query, sales_type: btn.type }}"
      :text="!active(btn)"
      style="margin-left: 2px; margin-right: 2px;"
    >{{ btn.text }}</v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnList: [
        {
          type: 'salesComp',
          text: 'Sales',
        }, {
          type: 'leaseComp',
          text: 'Leases'
        }
      ]
    }
  },

  methods: {
    gotoPage(btn) {
      this.$router.push({
        ...this.$route,
        query: {
          sales_type: btn.type
        }
      })
    },
    active(btn) {
      return this.$route.query.sales_type === btn.type
    }
  }
}
</script>

<style scoped>
.v-btn:before {
  opacity: 0;
}
</style>