import Vue from 'vue'
import { isLeapYear } from '@/helper/date'

const isValidNumber = (v) => {
  const isNumber = /^[+-]?\d+(\.\d+)?$/.test(v) // without ,
  // const isNumber = /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(v) // with , every 3 digits

  return isNumber
}

const currentYear = new Date().getFullYear()

Vue.prototype.$rules = {
  required: (value) => (value && Boolean(value)) || 'Required',
  length: (value) => (value && Array(value) && value.length > 0) || 'Choose at least one',
  emailFormat: (v) => /.+@.+\..+/.test(v) || 'Email format is not valid',
  emailFormatNullable: (v) => !v || /.+@.+\..+/.test(v) || 'Email format is not valid',
  numberOrNullRule: (v) => {
    if (v === null || v === '') {
      return true
    }

    const isNumber = isValidNumber(v)

    return isNumber || 'Invalid number'
  },
  yearOrNullRule: (v) => {
    if (v === null || v === '') {
      return true
    }

    const isNumber = isValidNumber(v)
    // const number = v.replace(/,/g, '')

    if (!isNumber) {
      return 'Invalid number'
      // return `Invalid number ${v}`
    } else if (v > currentYear || v <= 0) {
      return 'Invalid year'
    }

    return true
  },
  dateOrNullRule: (v) => {
    if (v === null || v === '') {
      return true
    }

    const [dd, mm, yyyy] = v.split('/')

    // Month validation
    if (mm < 1 || mm > 12) {
      return 'Date is invalid (Month should be 1 ~ 12)'
    }

    // Day validation
    const daysInMonth = [31, (isLeapYear(yyyy) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    if (dd < 1 || dd > daysInMonth[mm - 1]) {
      return `Date is invalid (Day should be 1 ~ ${daysInMonth[mm - 1]})`
    }

    return true
  },
  dateNotFuture: (v) => {
    if (v === null || v === '') {
      return true
    }

    const [dd, mm, yyyy] = v.split('/')

    if (yyyy < 1700 || yyyy > currentYear) {
      return `Year is invalid (Year should be 1700 ~ ${currentYear})`
    }

    if (new Date(yyyy, mm - 1, dd) > new Date()) {
      return 'Date is invalid (Date should be in the past)'
    }

    return true
  },
  percent: (v) => {
    const vv = parseFloat(v)

    return isNaN(vv) || vv < 0 || vv > 100 ? 'Invalid percentage value' : true
  },
  phoneFormat: (v) => /^(?:\(\d{3}\)|\d{3}-)\d{3}-\d{4}$/.test(v) || 'Phone number must be valid',
  website: (v) => v ? /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g.test(v) || 'Invalid website url' : true
}