<template>
  <div>
    <div>
      <v-autocomplete
        v-bind="$attrs"
        :id="id"
        :value="value"
        :items="displayItems"
        :search-input.sync="keyword"
        return-object
        outlined
        hide-details
        :item-text="contactName"
        placeholder="Type contact name here"
        hide-no-data
        append-icon=""
        :multiple="multiple"
        hide-selected
        prepend-inner-icon="$mdi-magnify"
        :loading="loading"
        :menu-props="{
          closeOnContentClick: true
        }"
        ref="autocomplete"
        @update:search-input="search"
        @input="handleInputChange"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            small
            @click="handleChipClick"
            @click:close="removeItem(data.item)"
          >
            {{ contactName(data.item) }}
          </v-chip>
        </template>
      </v-autocomplete>
      <div class="text-right">
        <span
          class="primary--text text-decoration-underline cursor-pointer caption"
          @click="handleOpenContactModal"
        >
          Create a new {{ mainType.toLowerCase() }}
        </span>
      </div>
    </div>

    <v-dialog
      v-model="contactModal"
      width="600"
      scrollable
      persistent
      no-click-animation
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="subtitle-2 font-weight-bold py-2 px-4">
          Create New {{ contactForm.main_type }}
        </v-card-title>
        <v-card-text class="mt-3 px-4 pb-3">
          <ContactForm
            ref="contactForm"
            v-model="contactForm"
            @close="contactModal = false"
            @completed="handleContactCreated"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'

import ContactForm from '@/components/forms/ContactForm'

import {
  defaultContactForm
} from '@/@propedia/default_forms'

export default {
  components: {
    ContactForm
  },

  props: {
    value: {
      type: [Array, Object],
      default: () => null
    },
    id: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false
    },
    queryTypes: {
      type: Array
    },
    mainType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      keyword: '',
      timerId: null,
      candidates: [],

      contactModal: false,
      contactForm: structuredClone({
        main_type: this.mainType,
        ...defaultContactForm
      })
    }
  },

  computed: {
    ...mapGetters('contact', ['contactName']),
    displayItems() {
      if (this.multiple) {
        const selectedIds = this.value.map(item => item.id)
        
        return [
          ...this.value,
          ...this.candidates.filter((v) => !selectedIds.includes(v.id))
        ]
      } else {
        if (this.value) {
          return [
            this.value,
            ...this.candidates.filter((v) => v.id !== this.value.id)
          ]
        } else {
          return this.candidates
        }
      }
    }
  },

  methods: {
    update(v) {
      this.$emit('input', v)
    },
    async search() {
      clearTimeout(this.timerId)

      if (this.keyword) {
        this.timerId = setTimeout(async () => {
          try {
            this.loading = true

            const response = await api.get('contacts/candidates', {
              params: {
                keyword: this.keyword,
                main_types: this.queryTypes
              }
            })

            this.candidates = response.data.items
          } finally {
            this.loading = false
          }
        }, 500)
      }
    },
    removeItem(item) {
      if (this.multiple) {
        this.update(this.value.filter((v) => v.id !== item.id))
      } else {
        this.update(null)
      }
    },
    handleChipClick() {
      this.$refs.autocomplete.focus()
    },
    async handleInputChange(v) {
      this.keyword = ''
      this.candidates = []

      this.update(v)
    },
    handleOpenContactModal() {
      this.contactForm = structuredClone({
        ...defaultContactForm,
        main_type: this.mainType
      }),
      this.contactModal = true
    },
    async handleContactCreated(contact) {
      this.keyword = ''
      this.candidates = []

      if (this.multiple) {
        this.update([...this.value, contact])
      } else {
        this.update(contact)
      }

      this.contactModal = false
    }
  }
}
</script>