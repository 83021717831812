<template>
  <div>
    <label for="pre-populate" class="caption">
      Pre-populate Land & Building info from previous transaction record(s)
    </label>
    <v-select
      v-model="selectedProfile"
      id="pre-populate"
      item-value="id"
      return-object
      outlined
      hide-details
      :items="profiles"
      @change="$emit('input', $event)"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex">
          <div v-if="item.land_area" class="d-flex align-center">
            Land Area: <NormalField type="number" :value="item.land_area" class="ml-1" /> <span class="ml-1">{{ item.land_area_unit }}</span>
          </div><span v-if="item.land_area && item.building_area" class="mr-1">,</span>
          <div v-if="item.building_area" class="d-flex align-center">
            Lettable Area: <NormalField type="number" :value="item.building_area" class="ml-1" /> <span class="ml-1">{{ item.building_area_unit }}</span>
          </div>
          <div class="d-flex ml-1">
            ({{ item.sale_date ? 'Sale Date:' : 'Commencement Date:' }}<NormalField type="date-format" :value="item.sale_date || item.lease_date" class="ml-1" />)
          </div>
        </div>
      </template>
      <template v-slot:selection="{ item }">
        <div>
          <div class="d-flex">
            <div v-if="item.land_area" class="d-flex align-center">
              Land Area: <NormalField type="number" :value="item.land_area" class="ml-1" /> <span class="ml-1">{{ item.land_area_unit }}</span>
            </div><span v-if="item.land_area && item.building_area" class="mr-1">,</span>
            <div v-if="item.building_area" class="d-flex align-center">
              Lettable Area: <NormalField type="number" :value="item.building_area" class="ml-1" /> <span class="ml-1">{{ item.building_area_unit }}</span>
            </div>
            <div class="d-flex ml-1">
              ({{ item.sale_date ? 'Sale Date:' : 'Commencement Date:' }}<NormalField type="date-format" :value="item.sale_date || item.lease_date" class="ml-1" />)
            </div>
          </div>
        </div>
      </template>
    </v-select>
  </div>
  <!-- <v-menu v-if="profiles && profiles.length" offset-y>
    <template v-slot:activator="{ on, attrs, value }">
      <div v-bind="attrs" v-on="on" class="d-flex align-center primary--text userselect-none" style="height: 20px;">
        {{ dropdownLabel }}
        <v-icon color="primary">{{ value ? '$mdi-menu-up' : '$mdi-menu-down' }}</v-icon>
      </div>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="item in profiles"
          :key="item.id"
          @click="$emit('input', item)"
        >
          <v-list-item-content class="py-1" :class="{ 'primary--text': selectedProfile?.id === item.id }">
            <div class="d-flex caption font-weight-bold">
              <div v-if="item.land_area" class="d-flex align-center">
                Land Area: <NormalField type="number" :value="item.land_area" class="ml-1" /> <span class="ml-1">{{ item.land_area_unit }}</span>
              </div><span v-if="item.land_area && item.building_area" class="mr-1">,</span>
              <div v-if="item.building_area" class="d-flex align-center">
                Lettable Area: <NormalField type="number" :value="item.building_area" class="ml-1" /> <span class="ml-1">{{ item.building_area_unit }}</span>
              </div>
            </div>

            <PropertyType :type-ids="item.type_ids" :color="selectedProfile?.id === item.id ? 'primary' : ''" />

            <div class="d-flex align-center caption mt-1">
              <div class="d-flex">
                <span>{{ item.salesType === 'salesComp' ? 'Sold At:' : 'Leased At:' }}</span>
                <NormalField type="date-format" :value="item.salesType === 'salesComp' ? item.sale_dt : item.lease_dt" class="ml-1" />
              </div>
              <span class="mx-1">for</span>
              <NormalField type="price" :value="item.salesType === 'salesComp' ? item.sale_price : item.commencing_rent" />
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu> -->
</template>

<script>
import NormalField from '@/components/attributes/NormalField'
// import PropertyType from '@/components/attributes/PropertyType'

export default {
  components: {
    NormalField,
    // PropertyType
  },
  props: [
    'profiles'
  ],
  data() {
    return {
      selectedProfile: null
    }
  },
  computed: {
    dropdownLabel() {
      return 'Pre-populate Land & Building info from previous transaction record(s)'
    }
  },
}
</script>