<template>
  <div>
    <v-form ref="form" v-model="valid" @input="$emit('validity-changed', $event)">
      <v-row dense>
        <v-col cols="6">
          <label for="sale_method_id" class="caption">
            {{ isSalesComp ? 'Sale Method (Optional)' : 'Lease Method (Optional)' }}
          </label>
          <DropdownSelect
            id="sale_method_id"
            v-model="local.sale_method_id"
            :ky="isSalesComp ? 'saleMethods' : 'leaseMethods'"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <label for="current_status_id" class="caption">Current Status (Optional)</label>
          <DropdownSelect
            id="current_status_id"
            v-model="local.current_status_id"
            :ky="isSalesComp ? 'campaignStatuses' : 'leaseCampaignStatuses'"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row dense v-if="isSalesComp">
        <v-col cols="6">
          <v-checkbox
            v-model="local.is_foreclosed"
            label="This sale is a mortgagee sale"
            hide-details
            class="mt-0 pt-0"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <label for="market_start_dt" class="caption">Campaign Start Date (Optional)</label>
          <DatePickerText
            id="market_start_dt"
            v-model="local.market_start_dt"
            dense
            outlined
            :rules="[
              $rules.dateOrNullRule,
              () => validateCampaignDates('start')
            ]"
          />
        </v-col>
        <v-col cols="6">
          <label for="market_end_dt" class="caption">Campaign End Date (Optional)</label>
          <DatePickerText
            id="market_end_dt"
            v-model="local.market_end_dt"
            dense
            outlined
            :rules="[
              $rules.dateOrNullRule,
              () => validateCampaignDates('end')
            ]"
          />
        </v-col>
      </v-row>

      <ExpandPanel label="Add agent details" class="mt-4">
        <v-row dense>
          <v-col cols="12">
            <div>
              <label for="agency1" class="caption">Agency 1</label>
              <ContactAutoComplete
                id="agency1"
                v-model="local.agency1"
                :multiple="false"
                main-type="Agency"
                :query-types="['Agency']"
                @input="update('agency1_id', local.agency1.id)"
              />
            </div>
  
            <div>
              <v-checkbox
                v-model="local.is_conjunction_sale"
                label="This is a conjunction Sale"
                hide-details
                class="mt-0 pt-0"
              />
            </div>
  
            <div v-if="local.is_conjunction_sale" class="mt-2">
              <label for="agency2" class="caption">Agency 2</label>
              <ContactAutoComplete
                id="agency2"
                v-model="local.agency2"
                :multiple="false"
                main-type="Agency"
                :query-types="['Agency']"
                @input="update('agency2_id', local.agency2.id)"
              />
            </div>
          </v-col>
        </v-row>
      </ExpandPanel>
      
      <ExpandPanel label="Add advertisement details" class="mt-5">
        <v-row dense>
          <v-col cols="12">
            <div v-for="i in 3" :key="i">
              <div>
                <label :for="`ad${i}_url`" class="caption">Ad{{ i }} URL</label>
                <v-text-field
                  :id="`ad${i}_url`"
                  v-model="local.ads[`ad${i}_url`]"
                  outlined
                  hide-details
                  prefix="https://"
                />
              </div>
              <div class="mt-2">
                <label class="caption">Ad{{ i }} Screenshot</label>
                <ImageUpload
                  v-model="local.ads[`ad${i}_images`]"
                  :id="`ad${i}_images`"
                  @input="updateAds(`ad${i}_images`, $event)"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </ExpandPanel>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import ImageUpload from '@/components/inputs/ImageUpload'

export default {
  props: [
    'value'
  ],

  components: {
    ImageUpload
  },

  data() {
    return {
      valid: true
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapGetters('dropdown', ['dropdownItemName']),
    ...mapGetters(['isSalesComp']),
    local() {
      return this.value
    }
  },

  mounted() {
  },

  methods: {
    ...mapActions('contact', ['createContact', 'getContacts']),
    validateCampaignDates(when) {
      if (this.local.market_start_dt && this.local.market_end_dt) {
        const [dd1, mm1, yyyy1] = this.local.market_start_dt.split('/')
        const [dd2, mm2, yyyy2] = this.local.market_end_dt.split('/')

        if (new Date(yyyy1, mm1 - 1, dd1) > new Date(yyyy2, mm2 - 1, dd2)) {
          return when === 'start' ? 'Start date shouldn\'t be more recent than end date' : 'End date should be more recent than start date'
        } else {
          return true
        }
      }

      return true
    },
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value })
    },
    updateAds(key, value) {
      this.$emit('input', {
        ...this.local,
        ads: {
          ...this.local.ads,
          [key]: value
        }
      })
    }
  }
}
</script>