<template>
  <div>
    <v-window
      v-model="step"
    >
      <v-window-item :value="1">
        <v-form ref="form" v-model="valid" class="mb-3">
          <v-row v-if="['Vendor', 'Purchaser', 'Landlord', 'Tenant'].includes(local.main_type)" dense>
            <v-col cols="6">
              <v-btn-toggle
                v-model="local.is_company"
                mandatory
                color="primary"
              >
                <v-btn
                  :value="false"
                  height="36"
                  class="flex-grow-1"
                >
                  Individual
                </v-btn>
                <v-btn
                  :value="true"
                  height="36"
                  class="flex-grow-1"
                >
                  Company
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <template v-if="local.is_company">
            <v-row dense>
              <v-col v-if="['Vendor', 'Purchaser'].includes(local.main_type)" cols="6">
                <label for="ownership_type_id" class="caption required">Investor Type <span>*</span></label>
                <DropdownSelect
                  id="ownership_type_id"
                  v-model="local.ownership_type_id"
                  ky="ownershipTypes"
                  :rules="[$rules.required]"
                />
              </v-col>
              <v-col cols="6">
                <label for="company_name" class="caption required">Company Name <span>*</span></label>
                <v-text-field
                  id="company_name"
                  v-model="local.company_name"
                  outlined
                  :rules="[$rules.required]"
                />
              </v-col>
            </v-row>
            <v-row v-if="['Vendor', 'Purchaser', 'LandLord'].includes(local.main_type)" dense>
              <v-col cols="4">
                <v-checkbox
                  v-model="local.is_overseas"
                  label="From Overseas"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <label for="business_type_id" class="caption">Industry</label>
                <DropdownSelect
                  id="business_type_id"
                  v-model="local.business_type_id"
                  ky="tenantIndustries"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <label for="company_website" class="caption">Company Website</label>
                <v-text-field
                  id="company_website"
                  v-model="local.company_website"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <label for="office_address_detail_pid" class="caption">Office Address</label>
                <SuburbAutoComplete
                  id="office_address_detail_pid"
                  v-model="office_address_detail_pid_loc"
                  clearable
                  outlined
                  hide-details
                  placeholder="e.g, 146 YARRA STREET ABBOTSFORD VIC 3067"
                  :full-address-only="true"
                  @input="handleAddressSelected"
                />
              </v-col>
              <v-col cols="6">
                <label for="suburb" class="caption">Office Suburb</label>
                <v-text-field
                  id="suburb"
                  v-model="officeSuburb"
                  outlined
                  readonly
                  hide-details
                />
              </v-col>
              <v-col cols="6">
                <label for="state" class="caption">Office State</label>
                <v-text-field
                  id="state"
                  v-model="officeState"
                  outlined
                  readonly
                  hide-details
                />
              </v-col>
              <v-col cols="6">
                <label for="office_phone" class="caption">Mobile</label>
                <PhoneText
                  id="office_phone"
                  v-model="local.office_phone"
                  outlined
                />
              </v-col>
              <v-col cols="6">
                <label for="office_email" class="caption">Email</label>
                <v-text-field
                  id="office_email"
                  v-model="local.office_email"
                  outlined
                  :rules="[$rules.emailFormatNullable]"
                />
              </v-col>
            </v-row>
          </template>

          <template v-else>
            <v-row dense>
              <v-col cols="6">
                <label for="full_name" class="caption required">Full Name <span>*</span></label>
                <v-text-field
                  id="full_name"
                  v-model="local.full_name"
                  outlined
                  hide-details
                  :rules="[$rules.required]"
                />
              </v-col>
              <v-col v-if="['Vendor', 'Purchaser'].includes(local.main_type)" cols="6">
                <label for="ownership_type_id" class="caption required">Investor Type <span>*</span></label>
                <DropdownSelect
                  id="ownership_type_id"
                  v-model="local.ownership_type_id"
                  ky="ownershipTypes"
                  :rules="[$rules.required]"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row v-if="['Vendor', 'Purchaser', 'LandLord'].includes(local.main_type)" dense>
              <v-col cols="4">
                <v-checkbox
                  v-model="local.is_overseas"
                  label="From Overseas"
                  hide-details
                  class="ml-n1"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <div>
                  <label for="contact_company_id" class="caption">Company</label>
                  <v-autocomplete
                    id="contact_company_id"
                    v-model="contact_company"
                    :items="displayItems"
                    :search-input.sync="keyword"
                    item-text="company_name"
                    return-object
                    placeholder="Type company name here"
                    hide-no-data
                    append-icon=""
                    outlined
                    chips
                    small-chips
                    hide-details
                    clearable
                    :loading="loading"
                    prepend-inner-icon="$mdi-magnify"
                    @update:search-input="search"
                    @input="handleInputChange"
                  >
                  </v-autocomplete>
                  <div class="text-right">
                    <span
                      class="primary--text text-decoration-underline cursor-pointer caption"
                      @click="step = 2"
                    >
                      Create a new company
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <label for="position" class="caption">Position</label>
                <v-text-field
                  id="position"
                  v-model="local.position"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="6">
                <label for="division" class="caption">Division</label>
                <v-text-field
                  id="division"
                  v-model="local.division"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="6">
                <label for="mobile" class="caption">Mobile</label>
                <PhoneText
                  id="mobile"
                  v-model="local.mobile"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="6">
                <label for="email" class="caption">Email</label>
                <v-text-field
                  id="email"
                  v-model="local.email"
                  outlined
                  hide-details
                  :rules="[$rules.emailFormatNullable]"
                />
              </v-col>
            </v-row>
          </template>

          <v-row dense>
            <v-col cols="12">
              <label for="comments" class="caption">Comments</label>
              <v-textarea
                id="comments"
                v-model="local.comments"
                outlined
                hide-details
                :clearable="false"
                rows="3"
              />
            </v-col>
          </v-row>
        </v-form>

        <div class="text-right">
          <v-btn text @click="$emit('close')">Cancel</v-btn>
          <v-btn
            depressed
            color="primary"
            :loading="savingContact"
            class="ml-1"
            @click="handleSaveContact"
          >
            Save
          </v-btn>
        </div>
      </v-window-item>
      <v-window-item :value="2">
        <v-form ref="companyForm" class="mb-3">
          <v-row dense>
            <v-col v-if="['Vendor', 'Purchaser'].includes(companyForm.main_type)" cols="6">
              <label for="ownership_type_id" class="caption required">Investor Type <span>*</span></label>
              <DropdownSelect
                id="ownership_type_id"
                v-model="companyForm.ownership_type_id"
                ky="ownershipTypes"
                outlined
                dense
                hide-details
                :rules="[$rules.required]"
              />
            </v-col>
            <v-col cols="6">
              <label for="company_name" class="caption required">Company Name <span>*</span></label>
              <v-text-field
                id="company_name"
                v-model="companyForm.company_name"
                dense
                outlined
                hide-details
                :rules="[$rules.required]"
              />
            </v-col>
          </v-row>
          <v-row v-if="['Vendor', 'Purchaser', 'LandLord'].includes(companyForm.main_type)" dense>
            <v-col cols="4">
              <v-checkbox
                v-model="companyForm.is_overseas"
                label="From Overseas"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <label for="business_type_id" class="caption">Industry</label>
              <DropdownSelect
                id="business_type_id"
                v-model="companyForm.business_type_id"
                ky="tenantIndustries"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <label for="company_website" class="caption">Company Website</label>
              <v-text-field
                id="company_website"
                v-model="companyForm.company_website"
                dense
                outlined
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <label for="office_address_detail_pid" class="caption">Office Address</label>
              <SuburbAutoComplete
                id="office_address_detail_pid"
                v-model="office_address_detail_pid_loc_2"
                clearable
                outlined
                hide-details
                placeholder="e.g, 146 YARRA STREET ABBOTSFORD VIC 3067"
                :full-address-only="true"
                @input="handleAddressSelected2"
              />
            </v-col>
            <v-col cols="6">
              <label for="suburb" class="caption">Office Suburb</label>
              <v-text-field
                id="suburb"
                v-model="officeSuburb2"
                outlined
                dense
                hide-details
                readonly
              />
            </v-col>
            <v-col cols="6">
              <label for="state" class="caption">Office State</label>
              <v-text-field
                id="state"
                v-model="officeState2"
                outlined
                dense
                hide-details
                readonly
              />
            </v-col>
            <v-col cols="6">
              <label for="office_phone" class="caption">Mobile</label>
              <v-text-field
                id="office_phone"
                v-model="companyForm.office_phone"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="6">
              <label for="office_email" class="caption">Email</label>
              <v-text-field
                id="office_email"
                v-model="companyForm.office_email"
                outlined
                dense
                hide-details
                :rules="[$rules.emailFormatNullable]"
              />
            </v-col>
          </v-row>
        </v-form>

        <div class="text-right">
          <v-btn text @click="step = 1">Back</v-btn>
          <v-btn
            depressed
            color="primary"
            :loading="savingContact"
            class="ml-1"
            @click="handleSaveContactCompany"
          >
            Save
          </v-btn>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/api'
import {
  defaultContactForm
} from '@/@propedia/default_forms'

import SuburbAutoComplete from '@/components/SuburbAutoComplete.vue'

export default {
  components: {
    SuburbAutoComplete
  },

  props: ['value'],

  data() {
    return {
      valid: true,
      office_address_detail_pid_loc: null,
      office_address_detail_pid_loc_2: null,
      officeSuburb: null,
      officeState: null,
      officeSuburb2: null,
      officeState2: null,
      savingContact: false,
      errorMessage: null,
      step: 1,
      companyForm: structuredClone({
        ...defaultContactForm,
        main_type: this.value.main_type,
        is_company: true
      }),

      loading: false,
      contact_company: null,
      keyword: '',
      timerId: null,
      contactCompanies: []
    }
  },

  computed: {
    local() {
      return this.value
    },
    displayItems() {
      if (this.contact_company) {
        return [
          this.contact_company,
          ...this.contactCompanies.filter((v) => v.id !== this.contact_company.id)
        ]
      } else {
        return this.contactCompanies
      }
    }
  },

  watch: {
    value() {
      this.officeSuburb = null
      this.officeSuburb2 = null
      this.officeState = null
      this.officeState2 = null
    }
  },

  mounted() {
  },

  methods: {
    ...mapActions('contact', ['getContacts', 'createContact']),
    ...mapActions('address', ['getAddress']),
    update(key, value) {
      this.$emit('input', {
        ...this.local,
        [key]: value
      })
    },
    handleAddressSelected(v) {
      this.update('office_address_detail_pid', v.value)
      this.getAddress({
        address_detail_pid: v.value
      }).then((res) => {
        this.officeSuburb = res.data.address.locality_name
        this.officeState = res.data.address.state_abbreviation
      })
    },
    handleAddressSelected2(v) {
      this.companyForm.office_address_detail_pid = v.value
      this.getAddress({
        address_detail_pid: v.value
      }).then((res) => {
        this.officeSuburb2 = res.data.address.locality_name
        this.officeState2 = res.data.address.state_abbreviation
      })
    },
    async search() {
      clearTimeout(this.timerId)

      if (this.keyword) {
        this.timerId = setTimeout(async () => {
          try {
            this.loading = true

            const response = await api.get('contacts/candidates', {
              params: {
                keyword: this.keyword,
                is_company: true
              }
            })

            this.contactCompanies = response.data.items
          } finally {
            this.loading = false
          }
        }, 500)
      }
    },
    async handleInputChange(v) {
      this.keyword = ''
      this.contactCompanies = []

      this.update('contact_company_id', v ? v.id : null)
    },
    async handleSaveContact() {
      if (this.$refs.form.validate()) {
        try {
          this.savingContact = true

          const res = await this.createContact(this.local)

          this.$emit('completed', res.data.contact)
        } catch (e) {
          if (e.response && e.response.status === 400) {
            this.errorMessage = e.response.data.message
          } else {
            this.errorMessage = 'Something went wrong'
          }
        } finally {
          this.savingContact = false
        }
      }
    },
    async handleSaveContactCompany() {
      if (this.$refs.companyForm.validate()) {
        try {
          this.savingContact = true

          const res = await this.createContact(this.companyForm)

          this.contact_company = res.data.contact
          this.update('contact_company_id', res.data.contact.id)
          this.step = 1
        } catch (e) {
          if (e.response && e.response.status === 400) {
            this.errorMessage = e.response.data.message
          } else {
            this.errorMessage = 'Something went wrong'
          }
        } finally {
          this.savingContact = false
        }
      }
    },
  }
}
</script>