<template>
  <div>
    <v-form ref="form" v-model="isFormValid" @input="$emit('validity-changed', $event)">
      <v-row dense>
        <v-col cols="6">
          <label for="source_type_id" class="caption required">Main Type <span>*</span></label>
          <DropdownSelect
            id="source_type_id"
            v-model="local.source_type_id"
            ky="sourceOfInfos"
            hide-details
            :rules="[$rules.required]"
            @input="handleSourceTypeChange"
          />
        </v-col>
        <!-- Newspaper/Publication -->
        <template v-if="local.source_type_id === 33">
          <v-col cols="6" style="align-self: end;">
            <DropdownSelect
              id="source_subtype_id"
              v-model="local.source_subtype_id"
              ky="newsPublishers"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <label for="publication_dt" class="caption">Publication Date</label>
            <DatePickerText
              id="publication_dt"
              v-model="local.publication_dt"
              dense
              outlined
              hide-details
            />
          </v-col>
        </template>
        <!-- 3rd Party Data Provider -->
        <template v-else-if="local.source_type_id === 31">
          <v-col cols="6" style="align-self: end;">
            <DropdownSelect
              id="source_subtype_id"
              v-model="local.source_subtype_id"
              ky="thirdPartyData"
              hide-details
            />
          </v-col>
        </template>
        <!-- Advertising Website -->
        <template v-else-if="local.source_type_id === 34">
          <v-col cols="6" style="align-self: end;">
            <DropdownSelect
              id="source_subtype_id"
              v-model="local.source_subtype_id"
              ky="advertisingWebsites"
              hide-details
            />
          </v-col>
        </template>
        <!-- Valuation Report -->
        <template v-else-if="local.source_type_id === 35">
          <v-col cols="6" style="align-self: end;">
            <label for="valuation_report_number" class="caption">Report Number (optional)</label>
            <v-text-field
              id="valuation_report_number"
              v-model="local.valuation_report_number"
              outlined
              dense
              hide-details
            />
          </v-col>
        </template>
        <!-- Other -->
        <template v-else-if="local.source_type_id === 100">
          <v-col cols="6" style="align-self: end;">
            <label for="extra_info" class="caption">Comments (optional)</label>
            <v-textarea
              id="extra_info"
              v-model="local.extra_info"
              outlined
              dense
              hide-details
              rows="3"
            />
          </v-col>
        </template>
        <!-- Not Other -->
        <template v-else-if="local.source_type_id && !([32, 36, 37, 38, 100].includes(local.source_type_id))">
          <v-col cols="12">
            <label for="contact" class="caption">Select a {{ sourceName() }}</label>
            <ContactAutoComplete
              id="contact"
              v-model="local.contact"
              :multiple="false"
              :main-type="sourceName()"
              :query-types="[sourceName()]"
              @input="update('contact_id', local.contact.id)"
            />
          </v-col>
        </template>
        <v-col cols="12">
          <label for="src_url" class="caption">Source URL (optional)</label>
          <v-text-field
            id="src_url"
            v-model="local.src_url"
            outlined
            dense
            hide-details
            prefix="https://"
            clearable
            clear-icon="$mdi-close"
            :rules="[$rules.website]"
            @keyup.enter="submit"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <label class="caption">Screenshots</label>
          <ImageUpload
            v-model="local.images"
            id="src-of-info-images"
            class="mt-1"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'

import ImageUpload from '@/components/inputs/ImageUpload'

export default {
  components: {
    ImageUpload
  },

  props: ['value', 'loading', 'showReviewers'],

  data() {
    return {
      isFormValid: true,
      reviewers: []
    }
  },

  computed: {
    ...mapGetters('dropdown', ['dropdownItemName']),
    local() {
      return this.value
    }
  },

  mounted() {
    this.getReviewers()
    if (this.local.source_type_id) {
      this._getContacts()
    }
  },

  methods: {
    update(key, value) {
      this.$emit('input', {
        ...this.local,
        [key]: value
      })
    },
    sourceName() {
      return this.dropdownItemName(this.local.source_type_id, 'sourceOfInfos')
    },
    getReviewers() {
      this.loadingReviewers = true

      api.get('/users/reviewers').then((res) => {
        this.reviewers = res.data.users
      }).finally(() => {
        this.loadingReviewers = false
      })
    },
    handleSourceTypeChange() {
      this.update('source_subtype_id', null)
      this.update('contact', null)
    },
  }
}
</script>