<template>
  <v-layout>
    <!-- Toolbar -->
    <v-app-bar
      color="white"
      app
      clipped-left
      clipped-right
      style="z-index: 8;"
    >
      <div
        class="d-flex flex-grow-1 justify-space-between align-center mx-auto"
      >
        <div cols="4" class="h-100 py-0 pl-0 d-flex align-center">
          <logo-component />
          <SalesTypeToggler v-if="salesTypeRoutes.includes($route.name)" class="ml-8"/>
        </div>

        <div cols="2" class="text-center py-0">
          <v-btn-toggle
            v-if="isBrowse"
            :value="viewMode"
            rounded
            dense
            color="primary"
          >
            <v-btn
              v-for="(btn, i) in typeList"
              :key="i"
              :value="btn.value"
              :to="{ name: `browse.${btn.value}`, query: $route.query }"
            >
              <v-icon :color="$route.name.includes(btn.value) ? 'primary' : ''">{{ btn.icon }}</v-icon>
              <span class="d-none d-md-block">{{ btn.text }}</span>
            </v-btn>
          </v-btn-toggle>
          <SalesTypeToggler v-if="$route.name === 'comps.create'" />
        </div>

        <div cols="6" class="d-flex align-center justify-end py-0 pr-0 gap-sm">
          <v-btn
            v-if="isBrowse"
            color="primary"
            text
            :href="`/comps/create?sales_type=${salesType}`"
          >
            <v-icon left>$mdi-plus</v-icon>
            Add New
          </v-btn>
          <v-btn
            v-if="!isBrowse"
            color="primary"
            text
            :href="`/browse/map?sales_type=${salesType}`"
          >
            <v-icon left>$mdi-compass-outline</v-icon>
            Browse comps
          </v-btn>
          <user-menu v-if="isAuth" />
        </div>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      :value="$route.meta.navigation === 'default'"
      app
      clipped
      stateless
      floating
      hide-overlay
      class="elevation-3"
    >
      <v-list subheader dense nav>
        <template
          v-for="(item, i) in navigationItems"
        >
          <v-divider v-if="item.divider" :key="`divider-${i}`" class="my-2" />
          <v-subheader v-else-if="item.subheader" :key="`subheader-${i}`">{{ item.subheader }}</v-subheader>
          <v-list-item
            v-else
            :key="`item-${i}`"
            :to="item.to"
            link
            active-class="primary--text"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      :value="isBrowse && (viewMode === 'map' && mapMode || viewMode === 'list' && listSearchDrawer)"
      app
      clipped
      stateless
      floating
      hide-overlay
      :temporary="viewMode === 'map'"
      class="elevation-3"
      :width="isBrowse ? 360 : 280"
      :style="viewMode === 'map' ? 'top: 64px; height: calc(100vh - 64px);' : ''"
    >
      <template
        v-slot:append
        v-if="
          (viewMode === 'list' || viewMode === 'map' && mapMode === 'search') ||
          (viewMode === 'map' && mapMode === 'properties' && pageCount > 1)
        "
      >
        <v-toolbar
          dense
          elevation="3"
        >
          <div v-if="viewMode === 'list' || viewMode === 'map' && mapMode === 'search'" class="d-flex flex-grow-1 align-center">
            <v-btn text color="primary" @click="clearFilter">Clear Filter</v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn depressed color="primary">Save Search</v-btn> -->
          </div>

          <ListPagination
            v-if="viewMode === 'map' && mapMode === 'properties' && pageCount > 1"
            v-model="page"
            :total-visible="7"
            :show-options="false"
            :items-per-page="itemsPerPage"
            :total="total"
            @input="handlePageChange"
          />
        </v-toolbar>
      </template>
      <NavBtns v-model="mapMode" v-if="viewMode === 'map'"/>
      <SuburbAutoComplete
        v-if="viewMode === 'map'"
        id="map-address-search-input"
        v-model="addressSearchLoc"
        clearable
        solo-inverted
        flat
        hide-details
        rounded
        placeholder="Search for address or suburb"
        :show-suburb="true"
        height="48"
        style="
          position: absolute;
          top: 32px;
          min-width: 573px;
          width: 573px;
          transform: translate(75%, -50%);
          display: flex;
          flex-direction: column;
        "
        @input="handleAddressChange(addressSearchLoc)"
      />
      <!-- <template
        v-slot:prepend
        v-if="
          viewMode === 'list' ||
          (viewMode === 'map' && mapMode === 'search')
        "
      >
        <v-toolbar
          dense
          elevation="3"
        >
          <v-tabs
            v-if="viewMode === 'list' || (viewMode === 'map' && mapNavMode === 'search')"
            v-model="searchMode"
          >
            <v-tab key="search">
              Search Filter
            </v-tab>
            <v-tab key="saved-searches">
              Saved Searches
            </v-tab>
          </v-tabs>
          <v-tabs
            v-else-if="viewMode === 'map' && mapNavMode === 'reporting'"
            grow
          >
            <v-tab key="report">
              Report
            </v-tab>
            <v-tab key="manage-report">
              Manage Report
            </v-tab>
          </v-tabs>
        </v-toolbar>
      </template> -->
      <div v-if="isBrowse" style="height: calc(100vh - 160px);">
        <ListSearchForm
          v-if="viewMode === 'list' || viewMode === 'map' && mapMode === 'search'"
          v-model="filter"
        />
        <PropertiesList
          v-if="viewMode === 'map' && mapMode === 'properties'"
          :sales-type="$route.query.sales_type"
          :items="items"
          :get-items="getItems"
          :loading="loadingItems"
        />
        <ReportingPanel
          v-if="viewMode === 'map' && mapMode === 'reporting'"
        />
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view
        ref="browser"
        :filter="filter"
        :is-shortlist-visible="isShortlistVisible"
        @input-page="handlePageChange"
        @drawing-updated="filter = { ...filter, drawing: $event }"
        @suburb-changed="handleAddressChange($event)"
      ></router-view>

      <div id="bottom-sheet-container" style="position: absolute; z-index: 1;"></div>
      <v-tooltip v-if="isBrowse" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="px-4 shortlist-btn"
            :style="shortlistBtnStyle"
            :ripple="false"
            :color="viewMode === 'map' ? 'white' : 'white'"
            x-small
            @click="handleShortlistBtnClick"
          ><v-icon small>{{ isShortlistVisible ? '$mdi-chevron-down' : '$mdi-chevron-up' }}</v-icon></v-btn>
        </template>
        <span>Shortlist</span>
      </v-tooltip>
      <v-bottom-sheet
        v-if="isBrowse"
        hide-overlay
        v-model="isShortlistVisible"
        :content-class="shortlistBodyClass"
        persistent
        no-click-animation
        :retain-focus="false"
        eager
        attach="#bottom-sheet-container"
      >
        <v-sheet
          height="280px"
          class="px-2 py-1"
          style="position: relative; border-radius: 4px 4px 0 0;"
        >
          <v-overlay
            absolute
            :value="loadingShortlistComps"
            :z-index="5"
            opacity="0.16"
          >
            <loader />
          </v-overlay>
          <template>
            <div class="d-flex align-center justify-space-between pb-1">
              <v-select
                v-model="selectedUserShortlistId"
                :items="userShortlist"
                outlined
                dense
                hide-details
                item-text="title"
                item-value="id"
                style="max-width: 300px;"
                no-data-text="No shortlist created yet"
                @change="handleUserShortlistChange"
              >
              </v-select>
              <a href="#" class="ml-4 text-decoration-underline" @click.prevent="handleManageShortlistClick">Manage Shortlist</a>
              <v-spacer></v-spacer>
              <div>
                <v-btn depressed small :disabled="deletingAllShortlist" :loading="deletingAllShortlist" @click="handleAllShortlistDelete">
                  <v-icon left>$mdi-trash-can</v-icon>Clear All
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      small
                      :disabled="selectedUserShortlistId && shortlistComps.length === 0"
                      :loading="exportingShortlist"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                    >
                      <v-icon left>$mdi-download-box</v-icon>Export
                    </v-btn>
                  </template>
                  <v-list nav dense>
                    <v-list-item disabled @click="handleExportShortlistToCSV">
                      <v-list-item-title>Export to CSV</v-list-item-title>
                    </v-list-item>
                    <v-list-item disabled @click="handleExportShortlistToExcel">
                      <v-list-item-title>Export to Excel</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="handleExportShortlistToDoc">
                      <v-list-item-title>Export to Document</v-list-item-title>
                    </v-list-item>
                    <v-list-item disabled @click="handleExportShortlistToClipboard">
                      <v-list-item-title>Export to Clipboard</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>

            <v-data-table
              v-if="shortlistComps.length"
              id="shortlist-table"
              height="228"
              fixed-header
              :items="shortlistRows"
              :headers="headers"
              disable-sort
              hide-default-footer
              dense
              :items-per-page="-1"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-end">{{ item.text }}</td>
                  <td v-for="(col, j) in shortlistComps" :key="j">
                    <CompImage
                      v-if="item.columnName === 'image'"
                      :items="col.images"
                      height="120"
                      width="240"
                      class="mt-1 mb-2"
                    />
                    <CompFiles
                      v-else-if="item.columnName === 'file'"
                      :items="col.docs"
                    />
                    <ZoneOverlayField
                      v-else-if="item.columnName === 'zone' || item.columnName === 'overlay'"
                      :key="item.columnName"
                      :type="item.columnName"
                      :codes="col.building[`${item.columnName}_codes`]"
                      :state_abbreviation="col.state_abbreviation"
                    />
                    <div v-else-if="item.columnName === 'type_ids'">
                      <PropertyTypeField
                        :type-ids="col.building.type_ids"
                      />
                    </div>
                    <div v-else-if="item.group === attributeGroups.ADDRESS" :key="`address-${col.columnName}`">
                      <div v-if="col.is_multi_sale">
                        <NormalField
                          v-for="(profile, i) in col.individuals"
                          :key="i"
                          :value="profile.address[item.columnName]"
                        />
                      </div>
                      <NormalField
                        v-else
                        :value="col.address[item.columnName]"
                      />
                    </div>
                    <YesNoField
                      v-else-if="item.attributeType === 'yes_no'"
                      :key="`yes-no-${item.columnName}`"
                      hide-icon
                      :value="attributeValue(col, item)"
                    />
                    <MultiField
                      v-else-if="item.attributeType === 'multi'"
                      :key="`multi-${item.columnName}`"
                      :value="attributeValue(col, item)"
                      :ky="item.dropdownKey"
                      :type="item.attributeType"
                    />
                    <NormalField
                      v-else-if="item.attributeType === 'area'"
                      :key="`area-${item.columnName}`"
                      :value="[attributeValue(col, item), 'SQM']"
                      type="number"
                      has-unit
                    />
                    <NormalField
                      v-else
                      :key="`normal-${item.columnName}`"
                      :value="attributeValue(col, item)"
                      :ky="item.dropdownKey"
                      :type="item.attributeType"
                    />
                  </td>
                </tr>
              </template>
              <template v-for="item in shortlistComps" v-slot:[`header.${item.id}`]="{  }">
                <div :key="item.id" class="d-flex align-center grey lighten-2 px-4 py-1 subtitle-2">
                  <div class="flex-grow-1 text-center">{{ item.label }}</div>
                  <v-btn icon small @click="$refs.browser.handleDetailBtnClick(item)">
                    <v-icon small>$mdi-map-marker</v-icon>
                  </v-btn>
                  <v-btn icon small @click="handleShortlistDelete(item.id)">
                    <v-icon small>$mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`header.label`]="{}">
                <v-btn depressed small color="primary" class="mr-2" @click="handleColumnEditClick">
                  Columns
                </v-btn>
              </template>
            </v-data-table>
          </template>
          <div v-if="!shortlistComps.length" class="mt-12 text-center">
            <div class="text-h5">Your shortlist is empty</div>
            <div>Find sales or lease comparables you are interested in and add them to your shortlist.</div>
            <div>You can create various of reports with your shortlist.</div>
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <v-dialog
        v-model="userShortlistModalLoc"
        width="500"
        scrollable
        no-click-animation
      >
        <v-card width="100%">
          <v-card-title class="subtitle-2 font-weight-bold py-2 px-4">
            <span v-if="compsForShortlist.length">Add To Shortlist</span>
            <span v-else>Manage Shortlist</span>
            <v-icon class="ml-auto" @click="handleShortlistModalClose">$mdi-close</v-icon>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text v-if="userShortlist.length === 0" class="px-4">
            <v-alert
              type="info"
              border="left"
              dense
              text
              class="mt-2"
            >
              You don't have shortlist yet. You can add a shortlist.
            </v-alert>
          </v-card-text>
          <v-card-text v-if="userShortlist.length" class="py-3 px-4">
            <div v-if="compsForShortlist.length" class="mb-4">
              <div v-for="(comp, i) in compsForShortlist" :key="`comps-for-shortlist-${i}`" class="d-flex justify-space-between">
                <div>
                  {{ comp.address_label }}
                </div>
                <div class="text-right">
                  ({{ comp[priceKey] | number | currency }} | {{ formattedDate(comp[dateKey], 'd MMM yyyy') }})
                </div>
              </div>
            </div>

            <v-card
              v-for="(v, i) in userShortlist"
              :key="i"
              outlined
              class="d-flex align-center px-2 py-1"
              style="margin-top: -1px;"
              :style="i === 0 ? 'border-radius: 4px 4px 0 0;' : (i === userShortlist.length - 1 ? 'border-radius: 0 0 4px 4px;' : 'border-radius: 0;')"
            >
              <div v-if="compsForShortlist.length" class="mr-0" @click="handleToggleToUserShortlist(v)">
                <v-checkbox
                  readonly
                  :input-value="shortlistVal.includes(v.id)"
                  :disabled="v.loading"
                  hide-details
                  class="mt-0 pt-0"
                ></v-checkbox>
              </div>
              <div>
                {{ v.title }}
              </div>
              <div class="d-flex flex-row ml-auto">
                <v-btn icon @click="handleEditUserShortlist(v)">
                  <v-icon small>$mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="handleDeleteUserShortlist(v)">
                  <v-icon small>$mdi-trash-can</v-icon>
                </v-btn>
              </div>
            </v-card>
            <!-- <v-list dense>
              <template v-for="(v, i) in userShortlist">
                <v-list-item
                  :key="i"
                  class="px-0"
                >
                  <v-list-item-icon v-if="compIdForShortlist !== null" class="mr-0" @click="handleToggleToUserShortlist(v)">
                    <v-checkbox
                      readonly
                      :input-value="shortlistVal.includes(v.id)"
                      :disabled="v.loading"
                      hide-details
                      class="mt-0"
                    ></v-checkbox>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="v.title"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="d-flex flex-row">
                    <v-btn icon @click="handleEditUserShortlist(v)">
                      <v-icon small>$mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="handleDeleteUserShortlist(v)">
                      <v-icon small>$mdi-trash-can</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list> -->
          </v-card-text>

          <v-divider></v-divider>

          <v-expand-transition>
            <div v-show="shortlistEditId !== null">
              <v-card-text>
                <v-form
                  ref="userShortlistForm"
                  v-model="userShortlistFormValid"
                  lazy-validation
                >
                  <label for="shortlist-name" class="caption">Shortlist Name</label>
                  <v-text-field
                    id="shortlist-name"
                    v-model="newShortlistTitle"
                    outlined
                    hide-details
                    :rules="[$rules.required]"
                  />
                </v-form>
              </v-card-text>
            </div>
          </v-expand-transition>

          <v-card-actions class="px-4 justify-end">
            <div v-if="shortlistEditId === null" class="d-flex flex-grow-1 gap-sm">
              <v-btn depressed class="flex-grow-1" @click="handleAddUserShortlist"><v-icon left>$mdi-plus</v-icon>Add Shortlist</v-btn>
            </div>
            <div v-else class="d-flex gap-sm">
              <v-btn text @click="shortlistEditId = null">Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                :loading="addingUserShortlist"
                :disabled="addingUserShortlist"
                @click="handleUserShortlistCreate"
              >
                {{ shortlistEditId === -1 ? 'Create' : 'Save' }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="reportingTemplateSelectionModal"
        max-width="1200"
        scrollable
        persistent
        no-click-animation
        :retain-focus="false"
      >
        <v-card width="100%">
          <v-card-title class="subtitle-2 font-weight-bold py-2 px-4">
            <span>Select a Template and Export</span>
            <v-icon class="ml-auto" @click="handleReportingTemplateModalClose">$mdi-close</v-icon>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="pb-0">
            <v-row dense class="my-1">
              <v-col cols="3">
                <v-radio-group
                  v-model="reportingTemplate"
                  @change="handleReportingTemplateSelect"
                >
                  <v-radio
                    v-for="template in reportingTemplates"
                    :key="template.id"
                    :label="template.title"
                    :value="template"
                    class="template-checkbox"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="9" class="pb-0" style="margin-bottom: -2px; position: relative;">
                <div v-if="!reportingTemplate" class="d-flex justify-center align-center h-100">Please select a template</div>
                <template v-else>
                  <v-overlay v-if="generatingTemplate" opacity="0.16" absolute>
                    <Loader/>
                  </v-overlay>
                  <iframe
                    v-else
                    ref="docxPreview"
                    src=""
                    height="500"
                    width="100%"
                    frameborder="none"
                    sandbox="allow-scripts allow-same-origin"
                  ></iframe>
                </template>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="justify-end">
            <v-btn text @click="handleReportingTemplateModalClose">Cancel</v-btn>
            <v-btn
              color="primary"
              depressed
              @click="downloadReport"
            >Export Report</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ColumnCustomizationModal
        :value="columnUpdateDlg"
        :sales-type="salesType"
        :is-shortlist="columnUpdateMode === 'shortlist'"
      />

      <CompEditModal
        v-if="isBrowse"
        v-model="compEditModal"
        :sales-type="salesType"
        :comp-id="compIdForEdit"
        :show-reviewers="true"
        @updated="onCompEditCompleted"
      >
        <template #title v-if="$route.name === 'comp_reviews'">
          Review Comp
        </template>
      </CompEditModal>
    </v-main>
  </v-layout>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} from 'vuex'

import LogoComponent from '@/components/LogoComponent.vue'
import UserMenu from '@/components/UserMenu.vue'
import ListPagination from '@/components/ListPagination'
import SalesTypeToggler from '@/components/layout/SalesTypeToggler.vue'
import ListSearchForm from '@/components/forms/ListSearchForm'
import ReportingPanel from '@/components/panels/ReportingPanel'
import NavBtns from '@/components/map-navications/NavButtons.vue'
import PropertiesList from '../components/PropertiesList.vue'
import SuburbAutoComplete from '@/components/SuburbAutoComplete'
import CompEditModal from '@/components/modals/CompEdit'
import ZoneOverlayField from '@/components/attributes/ZoneOverlay.vue'
import CompImage from '@/components/CompImage.vue'
import CompFiles from '@/components/CompFiles.vue'
import ColumnCustomizationModal from '@/components/modals/ColumnCustomizationModal.vue'

import api from '@/api'
import download from "downloadjs"
import attributeGroups from '@/@propedia/constants/attribute_groups'
import { formattedDate } from '@/helper/date'

let abortController

export default {
  components: {
    LogoComponent,
    UserMenu,
    ListPagination,
    SalesTypeToggler,
    ListSearchForm,
    NavBtns,
    PropertiesList,
    SuburbAutoComplete,
    ReportingPanel,
    ZoneOverlayField,
    CompImage,
    CompFiles,
    CompEditModal,
    ColumnCustomizationModal
  },

  data() {
    return {
      attributeGroups,
      formattedDate,

      searchMode: 'search',
      drawing: null,
      page: +this.$route.query.page || 1,

      navigationItems: [
        {
          to: '/dashboard',
          icon: '$mdi-home-city',
          title: 'Dashboard'
        }, {
          subheader: 'Valuation'
        }, {
          to: '/jobs',
          icon: '$mdi-laptop',
          title: 'Jobs'
        }, {
          to: '/reports',
          icon: '$mdi-chart-bar',
          title: 'Reports'
        }, {
          subheader: 'Comps'
        }, {
          to: '/sales',
          icon: '$mdi-sale',
          title: 'My Comps'
        }, {
          to: '/comp_reviews',
          icon: '$mdi-domain',
          title: 'Reviews'
        }, {
          subheader: 'Management'
        }, {
          to: '/users',
          icon: '$mdi-account-multiple',
          title: 'Users'
        }, {
          to: '/addresses',
          icon: '$mdi-map-marker',
          title: 'Manual Addresses'
        }, {
          subheader: 'Account'
        }, {
          to: '/account',
          icon: '$mdi-card-account-mail',
          title: 'Account'
        }, {
          to: '/account_settings',
          icon: '$mdi-lock',
          title: 'Account Settings'
        }, {
          subheader: 'System Monitor'
        }, {
          to: '/activities_logs',
          icon: '$mdi-history',
          title: 'Activities & Logs'
        }, {
          to: '/files_backups',
          icon: '$mdi-file-table',
          title: 'Files & Backup'
        }, {
          divider: true
        }, {
          to: '/settings',
          icon: '$mdi-cog',
          title: 'Settings'
        }, {
          to: '/help',
          icon: '$mdi-help-circle-outline',
          title: 'Help'
        }, {
          to: '/send_feedback',
          icon: '$mdi-comment-quote',
          title: 'Send Feedback'
        }
      ],

      salesTypeRoutes: [
        'browse.list',
        'browse.map'
      ],
      viewModeBtnRoutes: [
        'browse.map',
        'browse.list',
        'vertical.create',
        'me.comps',
        'me.submissions',
        'me.reporting',
        'reporting.create'
      ],
      btnList: [
        {
          type: 'comps',
          text: 'Sales',
          disabled: false
        }, {
          type: 'lease_comps',
          text: 'Leases',
          disabled: false
        }
      ],
      typeList: [
        {
          icon: '$mdi-view-list',
          value: 'list',
          text: 'List View'
        }, {
          icon: '$mdi-map',
          value: 'map',
          text: 'Map View'
        }
      ],

      isShortlistVisible: false,
      userShortlist: [],
      deletingAllShortlist: false,
      exportingShortlist: false,
      shortlistEditId: null,
      newShortlistTitle: null,
      addingUserShortlist: false,
      userShortlistFormValid: false,
      shortlistVal: [],

      generatingTemplate: false,
      reportingTemplate: null,
      reportFilePath: null,
      reportingTemplates: null
    }
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapState('app', [
      'itemsPerPage',
      'listSearchDrawer',
      'listDetailDrawer'
    ]),
    ...mapState('address', [
      'addressSearch',
      'fullAddressCandidates',
      'suburbCandidates'
    ]),
    ...mapState('property', [
      'loadingItems',
      'total',
      'items',
      'compDetail',

      'mapNavMode',
      'loadingShortlistComps',
      'shortlistComps',
      'userShortlistModal',
      'compsForShortlist',
      'sledUserShortlistId',

      'columnUpdateDlg',
      'columnUpdateMode',

      'reportingTemplateSelectionModal',

      'compEditModal',
      'compIdForEdit'
    ]),
    ...mapGetters('property', ['pageCount']),
    ...mapGetters('address', ['candidates']),
    ...mapGetters('auth', [
      'isAuth',
      'userRole',
      'isCompany'
    ]),
    ...mapGetters([
      'dateKey',
      'priceKey'
    ]),
    ...mapState('settings', [
      'userShortlistColumns'
    ]),
    viewMode() {
      return this.$route.meta.navigation
    },
    isBrowse() {
      return this.viewMode === 'list' || this.viewMode === 'map'
    },
    isCreate() {
      return this.$route.name === 'comps.create'
    },
    navigationWidth() {
      return this.isBrowse ? 360 : undefined
    },
    showDrawer() {
      return this.viewMode && this.mapNavMode
      // return this.menuItems.length
    },
    topItems() {
      return []
    },
    leftItems() {
      return []
    },
    topMenuItems() {
      return this.topItems.filter((v) => {
        if (Array.isArray(v.for)) {
          return v.for.includes(this.userRole)
        } else {
          v.for === this.userRole
        }
      })
    },
    salesType() {
      return this.$route.query.sales_type
    },
    mapMode: {
      get: function() {
        return this.mapNavMode
      },
      set: function(m) {
        this.setMapNavMode(m)
      }
    },
    addressSearchLoc: {
      get() {
        return this.addressSearch
      },
      set(v) {
        return this.SET_ADDRESS_SEARCH(v)
      }
    },
    shortlistBodyClass() {
      if (this.viewMode === 'list') {
        if (!this.listSearchDrawer && !this.listDetailDrawer) {
          return 'shortlist-body'
        } else if (this.listSearchDrawer && !this.listDetailDrawer) {
          return 'shortlist-map-body'
        } else if (!this.listSearchDrawer && this.listDetailDrawer) {
          return 'shortlist-list-body-2'
        } else if (this.listSearchDrawer && this.listDetailDrawer) {
          return 'shortlist-list-body-3'
        }
      } else if (this.viewMode === 'map') {
        if (this.mapNavMode) {
          return 'shortlist-map-body'
        } else {
          return 'shortlist-body'
        }
      }

      return ''
    },
    filter: {
      get: function() {
        let typeIds = this.$route.query.type_ids
        let zoneCodes = {
          vic: [],
          nsw: []
        }
        let overlayCodes = this.$route.query.overlay_codes

        const priceRange = {
          value: 'all',
          from_price: null,
          to_price: null
        }
        const dateRange = {
          value: 'all',
          from_dt: null,
          to_dt: null
        }
        const buildingSizeRange = {
          from: {
            value: null,
            unit: 'SQM'
          },
          to: {
            value: null,
            unit: 'SQM'
          }
        }
        const landSizeRange = {
          from: {
            value: null,
            unit: 'SQM'
          },
          to: {
            value: null,
            unit: 'SQM'
          }
        }

        if (!!typeIds && typeof typeIds === 'string') typeIds = [typeIds];
        // if (!!zoneCodes && typeof zoneCodes === 'string') zoneCodes = [zoneCodes];
        if (!!overlayCodes && typeof overlayCodes === 'string') overlayCodes = [overlayCodes];

        // Zone filter
        if (this.$route.query.zone_codes) {
          zoneCodes = JSON.parse(this.$route.query.zone_codes)
        }

        // Price range filter
        if (this.$route.query.price_range_from) {
          priceRange.value = 'custom'
          priceRange.from_price = this.$route.query.price_range_from
        }
        if (this.$route.query.price_range_to) {
          priceRange.value = 'custom'
          priceRange.to_price = this.$route.query.price_range_to
        }
        if (this.$route.query.price_range) {
          priceRange.value = this.$route.query.price_range
        }

        // Date range filter
        if (this.$route.query.date_range_from) {
          dateRange.value = 'custom'
          dateRange.from_dt = this.$route.query.date_range_from
        }
        if (this.$route.query.date_range_to) {
          dateRange.value = 'custom'
          dateRange.to_dt = this.$route.query.date_range_to
        }
        if (this.$route.query.date_range) {
          dateRange.value = this.$route.query.date_range
        }

        // Building size range filter
        if (this.$route.query.building_size_from) {
          buildingSizeRange.from = JSON.parse(this.$route.query.building_size_from)
        }
        if (this.$route.query.building_size_to) {
          buildingSizeRange.to = JSON.parse(this.$route.query.building_size_to)
        }

        // Land size range filter
        if (this.$route.query.land_size_from) {
          landSizeRange.from = JSON.parse(this.$route.query.land_size_from)
        }
        if (this.$route.query.land_size_to) {
          landSizeRange.to = JSON.parse(this.$route.query.land_size_to)
        }

        return {
          drawing: this.$route.query.drawing,
          typeIds: typeIds ? typeIds.map((id) => +id) : [],
          dateRange,
          priceRange,
          buildingSizeRange,
          landSizeRange,
          zoneCodes: zoneCodes,
          overlayCodes: overlayCodes ?? [],
          full_address: this.$route.query.full_address,
          address_detail_pid: this.$route.query.address_detail_pid,
          suburb: this.$route.query.suburb,
          is_multi_sale: this.$route.query.is_multi_sale,
          tenure_type_id: this.$route.query.tenure_type_id === 'All' ? 'All' : +this.$route.query.tenure_type_id,
          existing_rights_of_transfer_id: this.$route.query.existing_rights_of_transfer_id === 'All' ? 'All' : +this.$route.query.existing_rights_of_transfer_id
        }
      },
      set: async function(newVal) {
        let price_range = null, price_range_from = null, price_range_to = null
        let date_range = null, date_range_from = null, date_range_to = null

        if (newVal.priceRange) {
          price_range = newVal.priceRange.value

          if (newVal.priceRange.from_price || newVal.priceRange.to_price) {
            price_range = 'custom'
          }

          price_range_from = newVal.priceRange.from_price
          price_range_to = newVal.priceRange.to_price
        }

        if (newVal.dateRange) {
          date_range = newVal.dateRange.value

          if (newVal.dateRange.from_dt || newVal.dateRange.to_dt) {
            date_range = 'custom'
          }

          date_range_from = newVal.dateRange.from_dt
          date_range_to = newVal.dateRange.to_dt
        }

        this.drawing = newVal.drawing

        try {
          await this.getItems(newVal)

          const query = {
            ...this.$route.query,
            drawing: newVal.drawing,
            type_ids: newVal.typeIds?.length ? newVal.typeIds : null,

            price_range,
            price_range_from,
            price_range_to,

            date_range,
            date_range_from,
            date_range_to,

            building_size_from: JSON.stringify(newVal.buildingSizeRange?.from),
            building_size_to: JSON.stringify(newVal.buildingSizeRange?.to),
            land_size_from: JSON.stringify(newVal.landSizeRange?.from),
            land_size_to: JSON.stringify(newVal.landSizeRange?.to),
            page: newVal.page,
            zone_codes: JSON.stringify(newVal.zoneCodes),
            overlay_codes: newVal.overlayCodes ?? null,
            address_detail_pid: newVal.address_detail_pid,
            suburb: newVal.suburb,
            is_multi_sale: newVal.is_multi_sale,
            tenure_type_id: newVal.tenure_type_id,
            existing_rights_of_transfer_id: newVal.existing_rights_of_transfer_id
          }

          const validatedQuery = structuredClone(query)

          for(const key in validatedQuery) {
            if (!validatedQuery[key]) {
              delete validatedQuery[key]
            }

            if (validatedQuery[key] === 'All' || validatedQuery[key] === 'all') {
              delete validatedQuery[key]
            }

            if (['building_size_from', 'building_size_to', 'land_size_from', 'land_size_to'].includes(key)) {
              if (validatedQuery[key] && JSON.parse(validatedQuery[key]).value === null) {
                delete validatedQuery[key]
              }
            }
          }

          this.$router.push({
            ...this.$route,
            query: validatedQuery
          })
        } catch {
          // do nothing
        }
      }
    },
    headers() {
      return [
        {
          value: 'label',
          align: 'right',
          width: '200'
        },
        ...this.shortlistComps.map((item) => {
          return {
            value: item.id
          }
        })
      ]
    },
    selectedUserShortlistId: {
      get: function() {
        return this.sledUserShortlistId
      },
      set: function(id) {
        this.SET_SELECTED_USERSHORTLIST_ID(id)
      }
    },
    shortlistRows() {
      return this.userShortlistColumns[this.salesType]
    },
    shortlistBtnStyle() {
      return {
        left: this.viewMode === 'map' && this.mapNavMode ? 'calc(50% + 180px)' : '50%',
        bottom: this.isShortlistVisible ? '280px' : '0',
        boxShadow: this.isShortlistVisible ? 'none' : '',
        transition: '0.3s cubic-bezier(0.25, 0.8, 0.25, 1)'
      }
    },
    userShortlistModalLoc: {
      get() {
        return this.userShortlistModal
      },
      set(v) {
        this.SET_USER_SHORTLIST_MODAL(v)
      }
    }
  },

  watch: {
    viewMode() {
      if (this.isBrowse) {
        this.SET_ITEMS([])
        this.getItems()
      }
    },
    salesType() {
      if (this.isBrowse) {
        this.getReportingTemplates()
        this.filter = {
          ...this.filter,
          priceRange: null
        }
        // this.getItems()
        this.selectedUserShortlistId = null
        this.closeShortlistPanel()
        this._getUserShortlist()
      }
    },
    userShortlistModal(v) {
      if (v) {
        this.initializeChecks()
      }
    }
  },

  mounted() {
    this.init()
    if (this.isBrowse) {
      this.getReportingTemplates()
      this.getItems()
      this._getUserShortlist()
      this.initAddressSearch()
    }
  },

  methods: {
    ...mapActions('settings', ['getColumns', 'getUserColumns']),
    ...mapActions('auth', ['logout']),
    ...mapActions('zoning_and_overlay', [
      'getCodes'
    ]),
    ...mapActions('property', [
      'getComps', 'getCompDetails',
      'setMapNavMode',
      'getUserShortlist',
      'toggleToUserShortlist',
      'getShortlistedComps',
      'addUserShortlist',
      'deleteUserShortlist',
      'updateUserShortlist',
      'deleteShortlist'
    ]),
    ...mapActions('address', ['mockSuburbCandidates']),
    ...mapActions('dropdown', ['getDropdowns']),
    ...mapMutations('address', ['SET_ADDRESS_SEARCH']),
    ...mapMutations('property', [
      'SET_ITEMS',
      'SET_EDIT_MODAL', 'SET_COMPID_FOR_EDIT',
      'SET_USER_SHORTLIST_MODAL',
      'SET_COMPS_FOR_SHORTLIST',
      'SET_SELECTED_USERSHORTLIST_ID',
      'SET_SHORTLIST_COMPS',
      'SET_REPORTING_TEMPLATE_MODAL',

      'SET_COLUMN_EDIT_MODAL',
      'SET_COLUMN_EDIT_MODE'
    ]),
    init() {
      this.getDropdowns()
      this.getColumns().then(() => {
        this.getUserColumns()
      })

      this.getCodes()
    },
    async initAddressSearch() {
      const { address_detail_pid, suburb } = this.$route.query

      if (address_detail_pid || suburb) {
        await this.mockSuburbCandidates({
          address_detail_pid: address_detail_pid,
          suburb: suburb
        })

        if (address_detail_pid) {
          this.addressSearchLoc = {
            value: this.fullAddressCandidates[0].address_detail_pid,
            type: 'full_address',
            text: this.fullAddressCandidates[0].full_address
          }
        } else {
          this.addressSearchLoc = {
            value: this.suburbCandidates[0].value,
            type: 'suburb',
            text: this.suburbCandidates[0].display
          }
        }
      }
    },
    async getReportingTemplates() {
      const res = await api.get('/reporting/templates', {
        params: {
          sales_type: this.salesType
        }
      })

      this.reportingTemplates = res.data.items
    },
    async getItems(newVal = null) {
      if (abortController) {
        abortController.abort()
      }

      setTimeout(() => {
        abortController = new AbortController()

        this.getComps({
          salesType: this.salesType,
          with_full_detail: this.viewMode === 'map' ? false : true,
          page: newVal?.page || this.$route.query.page || 1,
          itemsPerPage: this.itemsPerPage,
          filterQuery: this.$route.query,
          signal: abortController.signal
        })
      }, 50)
    },
    handlePageChange($event) {
      this.filter = {
        ...this.filter,
        page: $event
      }
    },

    // Reporting template
    handleReportingTemplateModalClose() {
      this.SET_REPORTING_TEMPLATE_MODAL(false)
      this.reportingTemplate = null
      this.$refs.docxPreview.src = null
    },

    async handleReportingTemplateSelect() {
      this.generatingTemplate = true

      try {
        const res = await api.post('/reporting/generate', {
          salesType: this.salesType,
          template: this.reportingTemplate,
          selectedShortlistId: this.sledUserShortlistId
        })

        this.generatingTemplate = false

        this.$nextTick(() => {
          this.$refs.docxPreview.src = `https://docs.google.com/gview?url=${process.env.VUE_APP_SERVER_URI}/${res.data.filepath}&embedded=true`
          this.reportFilePath = res.data.filepath
        })
      } finally {
        this.generatingTemplate = false
      }
    },

    downloadReport() {
      const a = document.createElement('a')

      a.href = `${process.env.VUE_APP_SERVER_URI}/${this.reportFilePath}`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },

    // Shortlist
    async _getUserShortlist() {
      const res = await this.getUserShortlist(this.salesType)
      
      this.userShortlist = res.data.items.map((v) => ({
        ...v,
        loading: false
      }))
    },
    closeShortlistPanel() {
      this.isShortlistVisible = false
    },
    openShortlistPanel() {
      this.isShortlistVisible = true

      if (!this.selectedUserShortlistId && this.userShortlist.length) {
        this.selectedUserShortlistId = this.userShortlist[0].id

        this.getShortlistedComps({
          salesType: this.salesType,
          userShortlistId: this.selectedUserShortlistId
        })
      } else if (this.userShortlist.length === 0) {
        this.SET_SHORTLIST_COMPS([])
      }
    },
    async handleShortlistBtnClick() {
      if (this.isShortlistVisible) {
        this.closeShortlistPanel()
      } else {
        this.openShortlistPanel()
      }
    },
    handleManageShortlistClick() {
      this.shortlistEditId = null
      this.SET_COMPS_FOR_SHORTLIST([])
      this.SET_USER_SHORTLIST_MODAL(true)
    },
    handleUserShortlistChange(id) {
      this.getShortlistedComps({
        salesType: this.salesType,
        userShortlistId: id
      })
    },
    handleShortlistModalClose() {
      this.SET_COMPS_FOR_SHORTLIST([])
      this.SET_USER_SHORTLIST_MODAL(false)
    },
    async handleToggleToUserShortlist(userShortlist) {
      userShortlist.loading = true

      await this.toggleToUserShortlist({
        compIds: this.compsForShortlist.map((v) => v.id),
        userShortlistId: userShortlist.id,
        isAdd: !this.shortlistVal.includes(userShortlist.id)
      })

      this.initializeChecks()

      if (userShortlist.id === this.sledUserShortlistId) {
        await this.getShortlistedComps({
          salesType: this.salesType,
          userShortlistId: userShortlist.id
        })
      }
    },
    handleEditUserShortlist(shortlist) {
      this.shortlistEditId = shortlist.id
      this.newShortlistTitle = shortlist.title
    },
    handleDeleteUserShortlist(shortlist) {
      this.deleteUserShortlist(shortlist.id).then(() => {
        this._getUserShortlist()
      })
    },
    handleAddUserShortlist() {
      this.newShortlistTitle = ''
      this.$refs.userShortlistForm.reset()
      this.shortlistEditId = -1
    },
    async handleUserShortlistCreate() {
      if (this.$refs.userShortlistForm.validate()) {
        try {
          this.addingUserShortlist = true
          
          if (this.shortlistEditId === -1) {
            await this.addUserShortlist({
              title: this.newShortlistTitle,
              salesType: this.salesType
            })
          } else {
            await this.updateUserShortlist({
              id: this.shortlistEditId,
              title: this.newShortlistTitle
            })
          }

          this.initializeChecks()
          this.shortlistEditId = null
          this.newShortlistTitle = null
        } finally {
          this.addingUserShortlist = false
        }
      }
    },
    async handleShortlistDelete(compId) {
      await this.deleteShortlist({
        compId,
        userShortlistId: this.selectedUserShortlistId
      })
      await this.getShortlistedComps({
        salesType: this.salesType,
        userShortlistId: this.selectedUserShortlistId
      })
      
      this._getUserShortlist()
    },
    async handleAllShortlistDelete() {
      if (window.confirm('Are you really want to remove these shortlists?')) {
        try {
          this.deletingAllShortlist = true
  
          await this.deleteShortlist({
            userShortlistId: this.selectedUserShortlistId
          })
          await this.getShortlistedComps({
            salesType: this.salesType,
            userShortlistId: this.selectedUserShortlistId
          })
          
          this._getUserShortlist()
        } finally {
          this.deletingAllShortlist = false
        }
      }
    },
    async handleExportShortlistToCSV() {
      if (this.selectedUserShortlistId && this.shortlistComps.length) {
        try {
          this.exportingShortlist = true
          
          const res = await api.post('/shortlist/export/csv', {
            salesType: this.salesType,
            userShortlistId: this.selectedUserShortlistId
          }, {
            timeout: 5 * 60 * 1000
          })
  
          const selectedUserShortlist = this.userShortlist.find((v) => v.id === this.selectedUserShortlistId)
          
          download(res.data, `${selectedUserShortlist.title}.csv`)
        } finally {
          this.exportingShortlist = false
        }
      }
    },
    async handleExportShortlistToExcel() {
      if (this.selectedUserShortlistId && this.shortlistComps.length) {
        try {
          this.exportingShortlist = true
          
          const res = await api.post('/shortlist/export/excel', {
            salesType: this.salesType,
            userShortlistId: this.selectedUserShortlistId
          }, {
            timeout: 5 * 60 * 1000,
            responseType: 'blob'
          })

          const selectedUserShortlist = this.userShortlist.find((v) => v.id === this.selectedUserShortlistId)

          download(res.data, `${selectedUserShortlist.title}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        } finally {
          this.exportingShortlist = false
        }
      }
    },
    async handleExportShortlistToDoc() {
      this.SET_REPORTING_TEMPLATE_MODAL(true)
    },
    async handleExportShortlistToClipboard() {
      const copyTableToClipboard = (tableData) => {
        // Define the font family you want to apply
        const fontFamily = 'Arial, sans-serif';
        const borderStyle = 'border: 1px solid #CCC; border-collapse: collapse;'

        // Construct the HTML content of the table with inline CSS for font
        const htmlTable = `
          <table style="font-family: ${fontFamily}; ${borderStyle}; font-size: 13px;">
            ${tableData.map((row, i) => `
              <tr style="${borderStyle} ${i === 0 && 'font-weight: bold; background-color: #0573E6; color: white;'}">${row.map((cell, j) => {
                let span = ''

                if (i !== 0) {
                  if ((i + 1) % 3 === 0 || (i + 1) % 3 === 1) {
                    if (j === 0) {
                      span = 'colspan="2"'
                    }
                    if (j === 1) {
                      span = this.salesType === 'salesComp' ? 'colspan="7"' : 'colspan="9"'
                    }
                  }
                }

                return `
                  <td style="${borderStyle} ${i !== 0 && i % 3 === 0 && 'border-bottom: solid 2px #0573E6;'}" ${span}>${cell.value}</td>
                `
              }).join('')}</tr>
            `).join('')}
          </table>
        `;

        const type = 'text/html'
        const blob = new Blob([htmlTable], { type: type });

        const clipboardData = [
          new ClipboardItem({
            [type]: blob
          })
        ];

        navigator.clipboard.write(clipboardData)
          .then(() => {
            console.log('Table copied to clipboard successfully');
          })
          .catch(err => {
            console.error('Failed to copy table to clipboard:', err);
          });
      }

      if (this.selectedUserShortlistId && this.shortlistComps.length) {
        try {
          this.exportingShortlist = true
          
          const res = await api.post('/shortlist/export/clipboard', {
            salesType: this.salesType,
            userShortlistId: this.selectedUserShortlistId
          }, {
            timeout: 5 * 60 * 1000,
            // responseType: 'blob'
          })

          copyTableToClipboard(res.data.dataWithStyles)
        } finally {
          this.exportingShortlist = false
        }
      }
    },
    attributeValue(item, col) {
      switch(col.group) {
        case attributeGroups.SALES:
          return item[col.columnName]
        case attributeGroups.BUILDING:
        case attributeGroups.LAND:
        case attributeGroups.ANALYSIS:
        case attributeGroups.SOURCE:
          return item[col.columnName]
        default:
          return null
      }
    },
    async initializeChecks() {
      await this._getUserShortlist()

      this.shortlistVal = []
      this.userShortlist.forEach((v) => {
        if (this.compsForShortlist.every((vv) => {
          return v.compShortlist.map((vvv) => vvv.comp_id).includes(vv.id)
        })) {
          this.shortlistVal.push(v.id)
        }
      })
    },
    onCompEditCompleted() {
      if (this.compDetail) {
        this.getCompDetails({
          compId: this.compDetail.id,
          salesType: this.salesType
        })
      }

      this.getItems()

      this.SET_EDIT_MODAL(false)
      this.SET_COMPID_FOR_EDIT(null)
    },
    handleAddressChange(v) {
      if (!v) {
        this.filter = { ...this.filter, address_detail_pid: null, suburb: null }
      } else if (v.type === 'full_address') {
        this.filter = { ...this.filter, address_detail_pid: v.value, suburb: null }
      } else if (v.type === 'suburb') {
        this.filter = { ...this.filter, address_detail_pid: null, suburb: v.value }
      }
    },
    clearFilter() {
      this.filter = {}
      this.addressSearchLoc = null
    },
    handleColumnEditClick() {
      this.SET_COLUMN_EDIT_MODE('shortlist')
      this.SET_COLUMN_EDIT_MODAL(true)
    }
  }
}
</script>

<style lang="scss">
#shortlist-table {
  table {
    -webkit-border-horizontal-spacing: 8px;
    margin-left: -8px;
    padding: 0;
    width: initial !important;

    thead tr th {
      padding: 0 !important;
      min-width: 240px;
      max-width: 240px;
      width: 240px;
    }
    tbody tr td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
    td, th {
      border-right: solid 1px #e0e0e0;
      border-left: solid 1px #e0e0e0;
    }
    th:first-child {
      border-top: solid 1px #e0e0e0;
    }
    th:first-child, td:first-child {
      position: sticky;
      left: 0;
    }
    th:first-child {
      z-index: 3;
    }
    td:first-child {
      z-index: 2;
      background: white;
    }
  }
}
.template-checkbox.v-radio {
  display: flex;
  align-items: start;
  .v-label {
    line-height: 24px;
  }
}
.shortlist-body {
  overflow: auto !important;
  border-radius: 4px 4px 0 0 !important;
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.shortlist-map-body {
  overflow: auto !important;
  border-radius: 4px 4px 0 0 !important;
  margin-left: 372px !important;
  margin-right: 12px !important;
}
.shortlist-list-body-2 {
  overflow: auto !important;
  border-radius: 4px 4px 0 0 !important;
  margin-left: 12px !important;
  margin-right: 452px !important;
}
.shortlist-list-body-3 {
  overflow: auto !important;
  border-radius: 4px 4px 0 0 !important;
  margin-left: 372px !important;
  margin-right: 452px !important;
}
#map-address-search-input {
  .v-input__control > .v-input__slot {
    background: #ffffff99;
    box-shadow: 0px 0px 0px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  input {
    color: rgba(0, 0, 0, 0.87)
  }
  &.v-select--is-menu-active > .v-input__control > .v-input__slot {
    background: white;
    border-bottom: solid 1px rgba(0, 0, 0, 0.32);
    border-radius: 16px 16px 0 0;
  }
}
</style>

<style scoped>
.v-navigation-drawer--mini-variant, .v-navigation-drawer {
  overflow: visible !important;
}
.v-navigation-drawer--close {
  visibility: visible;
}
.shortlist-btn {
  z-index: 3;
  position: absolute;
  transform: translateX(-50%);
  bottom: 0;
  border-radius: 2px 2px 0 0;
  width: 80px;
}
</style>